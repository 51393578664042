import fp from 'lodash/fp';

const skeleton = {
  teamDetails: undefined,
  price: undefined,
};

const registrationTeams = {
  namespaced: true,
  state: {
    current: skeleton,
  },
  getters: {
    current(state, getters, rootState) {
      // since we are grabbing rego from different places
      const persistent = rootState.registrationTeamsSession;
      return {
        ...state.current,
        ...persistent.current,
      };
    },
  },
  mutations: {
    UPDATE_CURRENT(state, update) {
      const localUpdate = fp.cloneDeep(update);
      Object.keys(localUpdate).forEach(
        key => localUpdate[key] === undefined && delete localUpdate[key],
      );
      state.current = Object.assign({}, state.current, localUpdate);
    },
    CLEAR_CURRENT(state) {
      state.current = skeleton;
    },
  },
  actions: {
    updateCurrent({ commit }, { type, entity, ...rest }) {
      // commit in the persistent store
      commit(
        'registrationTeamsSession/UPDATE_CURRENT',
        {
          type,
          entity,
        },
        { root: true },
      );

      // commit rest to local
      commit('UPDATE_CURRENT', rest);
    },
    clearCurrent({ commit }) {
      // clear the persistent store
      commit('registrationTeamsSession/CLEAR_CURRENT', {}, { root: true });

      // clear local
      commit('CLEAR_CURRENT');
    },
  },
};

export default registrationTeams;
