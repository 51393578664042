const msg = {
  error: {
    paymentNotSupported: "Payment method not supported",
    invalidExpiry: "Invalid card expiry format, valid card expiry eg: 11/27",
    generic: "There has been an error",
    apiError: "There has been an error, try again later", // should be more detailed
    noDataError: "There were no results for your selection",
    noProducts:
      "There are no registration products available for you at this club right now. Please contact your club for more information",
    validationError: "Please complete highlighted fields",
    mobileError: "Please verify mobile number",
    sizeValidationError: "Please select a size",
    memberProfileCreate: "Failed to create member profile", // unusual (api error)
    unauthorized: "Not Authorized!", // nrl email unverified
    acceptTerms: "Please accept the terms and conditions",
    activeKidsInUse:
      "This voucher was already entered on this screen. Please make sure all codes are unique.",
    statedgeDiscountInUse: "Discount code already in use",
    voucherNotNeeded:
      "Vouchers cannot be added because the running total for this item is already zero",
    verification: {
      codeSentFail: "Unable to send code, please try again",
      fail3: "Too many failed attempts, please resend verification code",
      incorrect: "Oops, that isn't the correct code, please try again",
    },
    missingGender: "Please update the members gender in my profile to continue",
  },

  success: {
    regoAdd: "Registration details updated. Almost there! Continue to complete your registration",
    activeKidsAdded: "Voucher added",
    statedgeDiscountAdded: "Discount code added",
    verification: {
      codeSent: "Verification code sent!",
      userVerified:
        "Your profile has been successfully created. Thanks for verifying, your accounts are connected",
      memberVerified: "Member linked!",
    },
    unlink: "Successfully unlinked member!",
  },
};

export default msg;
