import msg from "@/utils/constants/msg";

const linkedMemberSkeleton = {
  // construct the regoForm init value from this
  id: undefined,
  mobile: undefined,
  email: undefined,
  dob: undefined,
  firstName: undefined,
  lastName: undefined,
};

const verify = {
  namespaced: true,
  state: {
    mobileSelected: true, // [true: mobile, false: email] - default to mobile
    linkProfileVerificationObj: linkedMemberSkeleton,
    codeSent: false,
    // controls whether user can navigate to verification code screen
    // mark the status as 'requiring code'
  },
  mutations: {
    MOBILE_MODE(state, update) {
      state.mobileSelected = update;
    },
    VERIFICATION_OBJECT(state, { mobile, email, id, dob, firstName, lastName }) {
      state.linkProfileVerificationObj = {
        id,
        mobile,
        email,
        dob,
        firstName,
        lastName,
      };
    },
    CODE_SENT(state, update) {
      state.codeSent = update; // boolean
    },
  },
  actions: {
    async verify1({ commit, getters }, { vm, mobileSelected }) {
      commit("root/LOADING", true, { root: true });
      const successMsg = {
        msg: msg.success.verification.codeSent,
        route: "verificationcode",
      };
      const msgError = {
        msg: msg.error.verification.codeSentFail,
        type: "warning",
        route: "verification",
      };

      const VERIFYING_USER = getters.userRequiresVerification;
      const {
        realVerificationObject: { id: memberId },
        endpointRequestCode,
      } = getters;

      // prepare the proper request body
      const reqBody = VERIFYING_USER ? {} : { memberId };
      commit("MOBILE_MODE", mobileSelected);
      reqBody.type = mobileSelected ? "mobile" : "email";

      let SUCCESS = false;
      try {
        const response = await vm.$http.post(endpointRequestCode, reqBody);
        if (response.data.status === "success") {
          // switch requiring code status
          // mark code as sent, next step is verify
          // clear code sent status after code sent
          commit("CODE_SENT", true);
          vm.$router.push({ name: "verificationcode", query: vm.$route.query });
          SUCCESS = true;
        }
      } catch (e) {}

      commit("root/LOADING", false, { root: true });
      commit("views/PUSH_NOTIFICATION", SUCCESS ? successMsg : msgError, { root: true });
      return SUCCESS;
    },

    async verify2({ commit, getters, dispatch, rootGetters }, { vm, code, current, rootUrl }) {
      commit("root/LOADING", true, { root: true });

      const VERIFYING_USER = getters.userRequiresVerification;

      const successMsg = {
        route: "chooseprofile",
        msg: VERIFYING_USER
          ? msg.success.verification.userVerified
          : msg.success.verification.memberVerified,
      };
      const msgError = {
        msg: msg.error.verification.incorrect,
        type: "warning",
        route: "verificationcode",
      };
      const REQUIRING_CODE = !code;

      const {
        realVerificationObject: { id: memberId },
        endpointSendCode,
      } = getters;

      // prepare the proper request body
      const reqBody = VERIFYING_USER ? {} : { memberId };
      reqBody.code = code;

      let SUCCESS = false;
      try {
        const response = await vm.$http.post(endpointSendCode, reqBody);
        if (response.data.status === "success") {
          // switch requiring code status
          // mark code as sent, next step is verify
          // clear code sent status after code sent
          commit("CODE_SENT", REQUIRING_CODE);

          if (VERIFYING_USER) {
            // mark user as verified
            dispatch("user/update", { verified: true }, { root: true });
          }
          SUCCESS = true;
          if (vm.$route.query && vm.$route.query.isLC) vm.$router.push({ name: "oidc-imc-oauth" });
          else {
            const profSelect = current && current.entity ? "chooseprofile" : undefined;
            if (rootUrl) {
              vm.$router.push({
                rootUrl,
              });
            } else
              vm.$router.push({
                name: profSelect || rootGetters["views/loginRedirect"] || "regotype",
              });
          }
        }
      } catch (e) {}

      commit("root/LOADING", false, { root: true });
      commit("views/PUSH_NOTIFICATION", SUCCESS ? successMsg : msgError, { root: true });

      return SUCCESS;
    },
  },
  getters: {
    sentTo(state, getters) {
      return state.mobileSelected
        ? getters.realVerificationObject.mobile
        : getters.realVerificationObject.email;
    },
    userRequiresVerification(state, getters, rootState) {
      return !rootState.user.profile.verified; // will also return true if user is not logged in
    },
    verifyingFor: (state, getters) => (getters.userRequiresVerification ? "user" : "member"),
    realVerificationObject(state, getters, rootState) {
      // return the correct object for verification, based on whether it is rego / profile
      if (getters.userRequiresVerification) {
        const { _id: id, mobile, email } = rootState.user.profile;
        return {
          id,
          mobile,
          email,
        };
      }
      return state.linkProfileVerificationObj;
    },
    endpointRequestCode(state, getters) {
      return `/nrl/api/v1/portal/verify/${getters.verifyingFor}/send-code`;
    },
    endpointSendCode(state, getters) {
      return `/nrl/api/v1/portal/verify/${getters.verifyingFor}/link`;
    },
  },
};

export default verify;
