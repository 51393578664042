const imcSessionStore = {
  namespaced: true,
  state: {
    response_type: '',
    client_id: '',
    redirect_uri: '',
    scope: '',
    state: '',
    response_mode: '',
    nonce: '',
  },
  mutations: {
    UPDATE_CURRENT(state, update) {
      Object.assign(state, update);
    },
  },
  getters: {
    everything(state) {
      return {
        response_type: state.response_type,
        client_id: state.client_id,
        redirect_uri: state.redirect_uri,
        scope: state.scope,
        state: state.state,
        response_mode: state.response_mode,
        nonce: state.nonce,
      };
    },
  },
};

export default imcSessionStore;
