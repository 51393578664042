<template>
  <div class="home">
    <div class="welcome">
      <div class="title">
        <h2>Welcome to <br />the home of Grassroots <br />Rugby League</h2>
      </div>
    </div>
    <div class="options">
      <el-button id="registration" @click="goRegister" type="primary">Register</el-button>
      <br />
      <el-button @click="goRegister" type="primary" class="mt-1">Find a place to play</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "home",
  components: {},
  methods: {
    goRegister() {
      this.$router.push("/register").catch(() => {});
    }
  }
};
</script>

<style lang="scss" scoped>
.home {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.welcome {
  width: 100%;
  background-color: #4a4a4a;
  padding: 30px 0px 60px 0px;

  .title {
    width: 80%;
    margin: 0 auto;

    @media (min-width: $sm) {
      width: 35%;
    }

    @media (min-width: $md) {
      width: 28%;
    }

    h2 {
      color: $white;
    }
  }
}

.options {
  width: 300px;
  background-color: $white;
  border: 1px solid #979797;
  padding: 15px;
  margin-top: -40px;
  display: flex;
  flex-direction: column;
}
</style>
