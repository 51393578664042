import fp from 'lodash/fp';

const skeleton = {
  type: undefined,
  entity: undefined,
};

export default {
  namespaced: true,
  state: {
    current: skeleton,
  },
  mutations: {
    UPDATE_CURRENT(state, update) {
      const localUpdate = fp.cloneDeep(update);
      Object.keys(localUpdate).forEach(
        key => localUpdate[key] === undefined && delete localUpdate[key],
      );
      state.current = Object.assign({}, state.current, localUpdate);
    },
    CLEAR_CURRENT(state) {
      state.current = skeleton;
    },
  },
};
