const root = {
  namespaced: true,
  state: {
    maintenanceCode: "snapcrackle",
    maintenanceBegin: parseInt(process.env.VUE_APP_MAINT_BEGIN), // 1637233200000, // 10pm th
    maintenanceEnded: parseInt(process.env.VUE_APP_MAINT_END), // 1637278200000,
    apiUrl: process.env.VUE_APP_API_URL,
    msUrl: process.env.VUE_APP_MICROSERVICE_URL,
    redirectUri: process.env.VUE_APP_REDIRECT_URI,
    redirectUriOidc: process.env.VUE_APP_REDIRECT_URI_OIDC,
    pinPk: process.env.VUE_APP_PIN_PK,
    pinEnv: process.env.VUE_APP_PIN_ENV,
    appToken: process.env.VUE_APP_TOKEN,
    rootLoading: false,
    rootOverlay: false,
  },
  mutations: {
    LOADING(state, update) {
      state.rootLoading = update;
    },
    OVERLAY(state, update) {
      state.rootOverlay = update;
    },
  },
  actions: {},
  getters: {
    maintenanceBegin(state) {
      return state.maintenanceBegin;
    },
    maintenanceEnded(state) {
      return state.maintenanceEnded;
    },
    maintenanceCode(state) {
      return state.maintenanceCode;
    },
    rootLoading(state) {
      return state.rootLoading;
    },
    rootOverlay(state) {
      return state.rootOverlay;
    },
    apiUrl(state) {
      return state.apiUrl;
    },
    msUrl(state) {
      return state.msUrl;
    },
    redirectUri(state) {
      return state.redirectUri;
    },
    redirectUriOidc(state) {
      return state.redirectUriOidc;
    },
    pinPk(state) {
      return state.pinPk;
    },
    pinEnv(state) {
      return state.pinEnv;
    },
    appToken(state) {
      return state.appToken;
    },
  },
};

export default root;
