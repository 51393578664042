/**
 * Helper: Returns All addons for registration
 *
 * @param {*} rego
 * @return [] addons
 */
export const getAllAddonsForRego = (rego) => {
  const { priceItem } = rego;
  const result = [];
  Object.entries(priceItem.pricing).forEach(
    ([category, value]) => {
      const { saleableItems = [] } = value;
      saleableItems.forEach(
        (item) => {
          const { qty } = item;
          result.push({
            // Item Category is for ease of updating (association, club, etc)
            category,
            ...item,
            qty: qty || 0,
          });
        },
      );
    },
  );
  return result;
};

/**
 * Helper: Returns Addon Items qty != 0
 *
 * @param {*} rego
 * @return [] addons
 */
export const getNotEmptyAddonsForRego = rego => getAllAddonsForRego(rego).filter(
  addon => addon.qty !== 0,
);

/**
 * TOTAL: Calculate Total Addons Amount (qty != 0)
 *
 * @param {*} regos
 * @return {Number} total
 */
export const regoAddonSubtotal = (rego) => {
  let subtotal = 0;
  getNotEmptyAddonsForRego(rego).forEach(
    (addon) => {
      subtotal += addon.qty * addon.price;
    },
  );
  return subtotal;
};

/**
 * CHECKOUT: Calculate Total Addons Amount
 *
 * @param {*} regos
 * @return {Number} total
 */
// eslint-disable-next-line import/prefer-default-export
export const totalAddonsCheckout = (regos) => {
  let total = 0;
  regos.forEach(
    (rego) => {
      total += regoAddonSubtotal(rego);
    },
  );
  return total;
};
