<template>
  <div
    style="text-align: center;background-color:white;box-shadow:0px 2px 6px rgba(0, 0, 0, 0.06);"
  >
    <div style="font-size:14px;font-weight:400;color:#444444; padding:10px 0 10px 0;">
      ROUND 1 - SUNDAY 31ST MARCH
    </div>
    <div class="containergrid">
      <div class="icon" style="">
        <img style="width:68px;height:68px;object-fit:contain;" src="~@/assets/parra.png" />
      </div>
      <div class="mid" style="font-size:20px; font-weight:700;">6:00 pm</div>
      <div class="iconrgt" style="">
        <img style="width:68px;height:68px;object-fit:contain;" src="~@/assets/parra.png" />
      </div>
      <div class="team" style="font-size:13px;font-weight:500;color:#222222">Dragons<br />4th</div>

      <div class="teamrgt" style="font-size:13px;font-weight:500;color:#222222">
        Warriors<br />2nd
      </div>
    </div>
    <div
      style="padding:12px 0 12px 0;border-top-style:solid; border-top-width:thin;border-color:#F2F2F2;font-size:14px;font-weight:400;color:#444444;"
    >
      GET TICKETS
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
.containergrid {
  display: grid;
  grid-template-rows: 68px 48px;
  grid-template-columns: 16px 68px 1fr 68px 16px;
}
.rowtop {
  grid-column: 1 / 6;
  grid-row: 1 / 1;
}
.rowfull {
  grid-column: 1 / 6;
}
.icon {
  grid-column: 2/3;
}
.iconrgt {
  grid-column: 4/5;
}

.team {
  grid-column: 2/3;
}
.teamrgt {
  grid-column: 4/5;
}
.mid {
  grid-column: 3/4;
  grid-row: 1/3;
  padding-top: 32px;
}
</style>
