<template>
  <div
    style="
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: white;
      box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
      padding: 20px 16px 20px 16px;
    "
  >
    <!-- <div style="font-size:16px;font-weight:500;color: #222222;">
      Play rugby league rewards
    </div> -->

    <!-- <a
      href="https://www.playrugbyleague.com/rewards"
      class="copycode"
      style="text-decoration:none;cursor:pointer;letter-spacing: 1px;padding-top:8px;padding-bottom:6px;width:311px;font-size:12px;font-weight:400;"
    >
      REWARDS
    </a> -->
    <div style="width: 311px; padding: 20px 8px 10px 8px; font-size: 14px; color: #222222">
      Play Rugby League Rewards offers registered players (born on or after 01/01/{{ yearCutOff }}),
      coaches, referees and volunteers FREE tickets to come and experience the NRL Telstra
      Premiership. <br /><br /><span style="font-weight: 600; color: black"
        >If eligible your code will be available once your registration is approved</span
      >; if you have any questions regarding this please see our
      <a
        href="https://support.playrugbyleague.com/hc/en-us/articles/360001404835-PlayNRL-Rewards-Program"
      >
        FAQ's or Submit a Request</a
      >
    </div>
  </div>
</template>

<script>
export default {
  props: ["profile"],

  methods: {
    copycode(message) {
      const clipboardData = navigator.clipboard || window.clipboardData || event.clipboardData;
      clipboardData.writeText(message);
      this.alertVisible = true;
    },
  },
  computed: {
    yearCutOff() {
      const date = new Date();
      const currentMonth = date.getMonth();
      let currentSeason;
      if (process.env.VUE_APP_PIN_ENV === "test") {
        const currentDay = date.getDate();
        currentSeason =
          (currentMonth === 10 && currentDay > 23) || currentMonth > 10
            ? date.getFullYear() + 1
            : date.getFullYear();
      } else {
        currentSeason = currentMonth <= 10 ? date.getFullYear() : date.getFullYear() + 1;
      }
      return currentSeason - 15;
    },
  },
  data() {
    return {
      alertVisible: false,
    };
  },
};
</script>

<style>
.copycode {
  background-color: white;
  color: #444444;
  border-radius: 4px;
  border: 1px solid #dddddd;
}
/* .copycode:hover { border:1px solid #DDDDDD;
  background-color: #098255;
  color: white;
} */
/* .copycode:focus {
  background-color: #098255;
  color: white;
} */
.copycode:focus {
  background-color: white;
  color: #444444;
  border: 1px solid #098255;
}
.copycode:active {
  background-color: rgb(152, 228, 175);
  color: #444444;
  border: 1px solid #098255;
}
</style>
