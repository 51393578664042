<template>
  <div class="rego d-flex-column flex-10">
    <div class="flex-10 d-flex-column container p-1 rego-container">
      <slot name="notifications"/>
      <router-view/>
    </div>
  </div>
</template>

<script>

export default {
  name: 'DefaultNoHeadings',
};
</script>

<style lang="scss" scoped>
  .rego-container {
    div {
      width: 100%
    }
  }

.rego {
  height: 100%;
  overflow-x: hidden;
  // background-color: $background;

  .container {
    padding: 15px 15px;
    height: 100%;
  }
}
</style>
