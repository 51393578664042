<template>
  <div class="mobile-menu-wrapper">
    <div @click="expanded = false" class="overlay" v-if="expanded" />
    <div
      :class="[expanded ? 'expanded' : null]"
      @click="expanded = !expanded"
      class="span-wrapper clickable"
    >
      <i class="icon-menu" />
    </div>

    <div class="placeholder"></div>

    <div
      class="d-flex logo-wrapper center clickable"
      :class="[expanded ? 'expanded' : null]"
      @click="$router.push({ name: 'regotype' })"
    >
      <img alt="nrl" :src="logo" />
    </div>

    <!-- REGISTER BTN -->
    <div class="header-button" :class="[expanded ? 'expanded' : null]" v-if="!inRegoFlow">
      <el-button
        @click="handleRegisterButtonClick"
        class="padless button-transparent button-thin register"
      >
        <span class="white upper button-text">
          Register
        </span>
      </el-button>
    </div>

    <!-- START MOBILE MENU -->
    <div class="mobile-menu">
      <div class="mobile-menu-inner">
        <!-- START MOBILE MENU ITEMS LOOP -->
        <li
          v-for="(item, index) in menu"
          :class="[
            $route.name !== 'under-construction' && item.path === $route.name ? 'active' : null
          ]"
          :index="String(index)"
          :key="index"
          @click="optionSelect(item)"
          class="mobile-menu-item"
        >
          <div class="link-option">
            {{ item.title }}
          </div>
        </li>

        <br />
        <!-- BREAK SPACE -->
        <!-- Item that are not in the loop -->
        <li v-if="isAuthenticated" class="mobile-menu-item">
          <div
            class="link-option text-normal-case clickable"
            @click="window.location = 'https://www.playrugbyleague.com/'"
          >
            <i class="el-icon-arrow-left" />
            Play Rugby League
          </div>
        </li>
        <li v-if="isAuthenticated" class="mobile-menu-item">
          <div
            class="link-option text-normal-case clickable"
            @click="window.location = 'https://touchfootball.com.au/'"
          >
            <i class="el-icon-arrow-left" />
            Play Touch Football
          </div>
        </li>
      </div>
      <div class="menu-bottom d-flex-column clickable" @click="handleLoginLogout">
        <div class="user-option">
          <i class="icon-user" />
          <span>
            {{ isAuthenticated ? "Log Out" : "Log In" }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "HeaderMobile",
  props: ["menu", "submenu", "nationalOrg"],
  data() {
    return {
      expanded: false,
      window
    };
  },
  watch: {
    path() {
      // watch for route change. close the sidebar on route change.
      this.expanded = false;
    }
  },
  methods: {
    optionSelect(item) {
      if (item.link && item.path) throw Error("Do not supply both link and path.");
      if (item.link) window.location = item.link;
      if (item.path) this.$router.push({ name: item.path }).catch(() => {});
      this.expanded = false;
    },
    login() {
      this.$router.push("/login").catch(() => {});
    },
    logout() {
      this.$router.push("/logout").catch(() => {});
    },
    handleRegisterButtonClick() {
      this.$router.push({ name: "regotype" }).catch(() => {});
      this.expanded = false;
    },
    handleLoginLogout() {
      this.expanded = !this.expanded;
      if (!this.isAuthenticated) {
        this.login();
      } else {
        this.logout();
      }
    }
  },
  computed: {
    ...mapGetters("views", ["bus"]),
    ...mapGetters("user", ["isAuthenticated", "userName"]),
    inRegoFlow() {
      const { path } = this.$route;
      return path && path.indexOf("register") !== -1;
    },
    logo() {
      if (this.nationalOrg) {
        if (this.nationalOrg.code === "rugby-league") return "/images/PlayRugbyLeague_Logo.svg";
        return "/images/NRL_TouchFootballv2.svg";
      }
      return "/images/MySideline_MobileLogo_white.svg";
    }
  }
};
</script>
<style lang="scss" scoped>
.padless {
  padding: 5px 10px 5px !important;
}

.search {
  position: absolute;
  bottom: 50px;
}

.logo-wrapper {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    display: block !important;
  }
  &.expanded {
    position: fixed;
  }

  z-index: 11;
  position: absolute;
  left: 65px;
  margin-top: 10px;

  img {
    height: 1.5rem;
  }
}

.text-normal-case {
  text-transform: none !important;
}

.mobile-menu-wrapper {
  display: flex;
  top: 25px;
  left: 25px;
  user-select: none;

  .expanded ~ .mobile-menu {
    transform: none;
  }
}

.username {
  color: $secondary;
}

.link-option {
  text-transform: uppercase;
  text-decoration: none;
  color: white;
  opacity: 1;
  letter-spacing: 0.2em;
  font-size: 1em;
  font-weight: 400;
  transition: 200ms;
}

.span-wrapper.expanded {
  position: fixed;
}

.span-wrapper {
  width: 50px;
  height: 44px;
  z-index: 999;

  // closed menu hamburger
  .icon-menu {
    display: inline-block;
    background-image: url("../../assets/icons/menu.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 22px;
    height: 100%;
    padding: 22px 11px;
    margin: 0px 16px;
  }

  // open menu hamburger
  &.expanded {
    .icon-menu {
      background-image: url("../../assets/icons/close-menu.svg");
    }
  }
}

.mobile-menu {
  .mobile-menu-inner {
    padding: 60px 0 50px;

    .mobile-menu-item {
      padding: 10px 30px 10px;
      border-bottom: none;
      width: 100%;
      cursor: pointer;

      &.active,
      &:hover {
        background-color: rgba(52, 52, 52);
        border-left: 3px solid $rl-highlight;
        padding-left: 27px;
      }
    }
  }

  .menu-bottom {
    z-index: 99;
    text-align: center;
    background-color: #1c2025;
    width: 100%;
    position: absolute;
    bottom: 0;
    height: 40px;

    color: white;

    .user-option {
      z-index: 99;
      margin-top: auto;
      margin-bottom: auto;
      font-size: 14px;
    }
    .icon-user {
      background-image: url("../../assets/icons/user.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: 14px;
      padding: 8px;
      margin-right: 10px;
    }
  }

  z-index: 10;
  list-style-type: none; // should be putting a ul under
  position: fixed;
  width: 100%;
  max-width: 100%; // prevent overflow
  height: 100%;
  background-color: $primary;
  transform-origin: 0 0;
  transform: translate(-150%, 0);
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
}

.overlay {
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.header-button {
  z-index: 11;
  position: absolute;
  right: 10px;
  margin-top: 7px;

  &.expanded {
    position: fixed;
  }

  .button-transparent.button-thin.register {
    height: calc(0.75rem + 18px);
    width: 100px;
    font-size: 0.75rem;
    border: 1px solid $rl-highlight !important;
    border-radius: 2px;

    .button-text {
      letter-spacing: 0.15em;
    }
  }
}

.white {
  color: white;
}
</style>
