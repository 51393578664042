const privateLink = {
  state: {
    rootUrl: "",
  },
  mutations: {
    setRootUrl(state, url) {
      state.rootUrl = url;
    },
  },
  actions: {
    fetchRootUrl({ commit }, { url }) {
      const rootUrl = url;
      commit("setRootUrl", rootUrl);
    },
  },
};

export default privateLink;
