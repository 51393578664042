import "core-js/stable";

import Vue from "vue";
import moment from "moment";
import VueGtm from "vue-gtm";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./plugins/element";
import httpauth from "./axios/httpauth";
import httpnoauth from "./axios/httpnoauth";
import httptoken from "./axios/httptoken";
import httpauthhard from "./axios/httpauthhard";
import httpms from "./axios/httpms";
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';

Vue.config.productionTip = false;

Vue.prototype.moment = moment;
Vue.prototype.$noauth = httpnoauth;
Vue.prototype.$httptoken = httptoken;
Vue.prototype.$http = httpauth;
Vue.prototype.$httphard = httpauthhard;
Vue.prototype.$httpms = httpms;

// dev only
Vue.prototype.DEV = process.env.NODE_ENV === "development";

Vue.use(VueTelInput);

Vue.use(VueGtm, {
  id: "GTM-PV42QSK",
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
