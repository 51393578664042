/* eslint-disable no-multi-spaces */
// NoAuth
const PreOAuth = [
  {
    path: "",
    name: "regotype",
    component: () => import("@/views/registration/RegoType.vue"),
    props: route => ({
      source: route.query.source
    }),
    meta: {
      requiresAuth: false,
      regoflow: false,
      requiresVerified: false,
      heading: "Register",
      gtm: {
        page_name: "Choose Your Path"
      },
      title: "Register – MySideline",
      metaTags: [
        {
          name: "description",
          content: "Player, coach, trainer and volunteer registration for Rugby League."
        },
        {
          name: "og:description",
          content: "Player, coach, trainer and volunteer registration for Rugby League."
        }
      ]
    }
  },
  // club search
  {
    path: "clubsearch",
    name: "clubsearch",
    component: () => import("@/views/registration/ClubSearch.vue"),
    props: route => ({
      criteria: route.query.criteria,
      type: route.query.type && route.query.type.split(","),
      activity:
        route.query.activity && route.query.activity !== ""
          ? route.query.activity.split(",")
          : undefined,
      gender: route.query.gender,
      agemin:
        route.query.agemin && route.query.agemin !== "" ? parseInt(route.query.agemin) : undefined,
      agemax:
        route.query.agemax && route.query.agemax !== "" ? parseInt(route.query.agemax) : undefined,
      comptype:
        route.query.comptype && route.query.comptype !== ""
          ? route.query.comptype.split(",")
          : undefined,
      source: route.query.source,
      entity: route.query.entity === "true",
      entitytype: route.query.entitytype,
    }),
    meta: {
      requiresAuth: false,
      requiresVerified: false,
      layout: "DefaultNoHeading",
      gtm: {
        page_name: "Finder"
      },
      title: "Club Finder – MySideline",
      metaTags: [
        {
          name: "description",
          content: "Search for a rugby league club to join by location or postcode."
        },
        {
          name: "og:description",
          content: "Search for a rugby league club to join by location or postcode."
        }
      ]
    }
  },
  // club search beta
  {
    path: "search-beta",
    name: "search",
    component: () => import("@/views/registration/beta/ClubSearch.vue"),
    props: route => ({
      criteria: route.query.criteria,
      type: route.query.type && route.query.type.split(","),
      activity:
        route.query.activity && route.query.activity !== ""
          ? route.query.activity.split(",")
          : undefined,
      gender: route.query.gender,
      agemin:
        route.query.agemin && route.query.agemin !== "" ? parseInt(route.query.agemin) : undefined,
      agemax:
        route.query.agemax && route.query.agemax !== "" ? parseInt(route.query.agemax) : undefined,
      comptype:
        route.query.comptype && route.query.comptype !== ""
          ? route.query.comptype.split(",")
          : undefined,
      source: route.query.source,
      entity: route.query.entity === "true",
      entitytype: route.query.entitytype,
    }),
    meta: {
      requiresAuth: false,
      requiresVerified: false,
      layout: "DefaultFullWidth",
      gtm: {
        page_name: "Finder"
      },
      title: "Club Finder – MySideline",
      metaTags: [
        {
          name: "description",
          content: "Search for a rugby league club to join by location or postcode."
        },
        {
          name: "og:description",
          content: "Search for a rugby league club to join by location or postcode."
        }
      ]
    }
  },
  {
    path: "loginrequired",
    name: "loginrequired",
    component: () => import("@/views/registration/LoginRequired.vue"),
    props: true,
    meta: {
      requiresAuth: false,
      requiresVerified: false,
      regoFlow: false,
      heading: "Register",
      gtm: {
        page_name: "You Need NRL Account"
      },
      title: "NRL Account – MySideline"
    }
  },
  {
    path: "entity",
    name: "registration",
    component: () => import("@/views/registration/PrivateRegistration.vue"),
    props: route => ({
      source: route.query.source,
      data: route.query.data
    }),
    meta: {
      requiresAuth: true,
      requiresVerified: true,
      regoFlow: false,
      heading: "Register",
      gtm: {
        page_name: "Registration"
      },
      title: "Registration - MySideline"
    }
  }
];

const Verification = [
  {
    path: "verification",
    name: "verification",
    component: () => import("@/views/auth/Verification.vue"),
    meta: {
      heading: "Verification",
      requiresVerified: false, // doesnt require verified
      title: "Verify Account – MySideline"
    }
  },
  {
    name: "verificationcode",
    path: "verificationcode",
    component: () => import("@/views/auth/VerificationCode.vue"),
    meta: {
      heading: "Verification",
      requiresVerified: false, // doesnt require verified
      title: "Verify Account – MySideline"
    }
  }
];

// 2 step forms
const profileDetails = [
  {
    path: "chooseprofile",
    name: "chooseprofile",
    component: () => import("@/views/registration/ChooseProfile.vue"),
    meta: {
      heading: "Choose Profile",
      title: "Choose Profile – MySideline"
    }
  },
  {
    path: "memberform",
    name: "memberform",
    component: () => import("@/views/registration/MemberForm.vue"),
    meta: {
      layout: "DefaultNoHeading",
      title: "Member Form – MySideline"
    }
  },
  {
    path: "linkmember",
    name: "linkmember",
    component: () => import("@/views/registration/PreviousProfileFound.vue"),
    meta: {
      heading: "Registration",
      title: "Link Member – MySideline"
    }
  },
  {
    path: "participantdetails",
    name: "participantdetails",
    component: () => import("@/views/registration/RegistrationForm.vue"),
    meta: {
      heading: "Registration",
      title: "Registration Form – MySideline"
    }
  },
  {
    path: "additionaldetails",
    name: "additionaldetails",
    component: () => import("@/views/registration/AdditionalQuestions.vue"),
    meta: {
      heading: "Registration",
      title: "Additional Details Form – MySideline"
    }
  },
  {
    path: "chooseaddon",
    name: "chooseaddon",
    component: () => import("@/views/registration/ChooseAddon.vue"),
    meta: {
      title: "Choose Addon – MySideline"
    }
  },
  {
    path: "verificationupload",
    name: "verificationupload",
    component: () => import("@/views/registration/VerificationUpload.vue"),
    props: { inRegoFlow: true },
    meta: {
      title: "Verification Upload – MySideline"
    }
  }
];

const Cart = [
  {
    path: "regocart",
    name: "regocart",
    component: () => import("@/views/registration/RegoCart.vue"),
    meta: {
      heading: "Registration",
      title: "Registrations Summary – MySideline"
    }
  },
  {
    path: "registeranother",
    name: "registeranother",
    component: () => import("@/views/registration/RegoAnother.vue"),
    meta: {
      heading: "Registration",
      title: "Another Registration – MySideline"
    }
  }
];

const Clearance = [
  {
    path: "clearanceform/:memberId/:memberType/:toClubId",
    name: "clearanceform",
    component: () => import("@/views/registration/ClearanceForm.vue"),
    meta: {
      heading: "Change Registration",
      title: "Change Registration Form – MySideline"
    },
    props: true
  },
  {
    path: "clearancecompleted",
    name: "clearancecompleted",
    component: () => import("@/views/registration/ClearanceComplete.vue"),
    meta: {
      heading: "Change Registration",
      title: "Change Registration Submitted – MySideline"
    }
  }
];

const Payment = [
  {
    path: "payment",
    name: "payment",
    component: () => import("@/views/registration/Payment.vue"),
    meta: {
      title: "Payment Summary – MySideline"
    }
  },
  {
    path: "checkout",
    name: "checkout",
    component: () => import("@/views/registration/Checkout.vue"),
    meta: {
      heading: "Payment",
      title: "Checkout – MySideline"
    }
  },
  {
    path: "complete",
    name: "paymentcomplete",
    component: () => import("@/views/registration/PaymentComplete.vue"),
    meta: {
      title: "Payment Complete – MySideline"
    }
  }
];

const Extras = [
  {
    path: "expressinterest",
    name: "expressinterest",
    component: () => import("@/views/registration/ExpressInterest.vue"),
    meta: {
      requiresAuth: false,
      regoflow: false,
      requiresVerified: false,
      heading: "Get In Contact",
      title: "Get In Contact – MySideline"
    }
  },
  {
    path: "contactreceived",
    name: "contactreceived",
    component: () => import("@/views/registration/ContactReceived.vue"),
    meta: {
      requiresAuth: false,
      regoflow: false,
      requiresVerified: false,
      heading: "Get In Contact",
      title: "Contact Sent – MySideline"
    }
  }
];

const rego = [
  ...PreOAuth, // choose rego type - no auth needed
  ...Verification, // send code + input code
  ...profileDetails, // 2-step form, main data entry point
  ...Cart, // RegoCart - manage multiple Rego
  ...Clearance, // clearance route - which takes user out of normal rego
  ...Payment, // payment
  ...Extras // express interests
];

export default rego;
