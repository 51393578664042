import { getAllAddonsForRego } from "./addon";

/* ------------------------- START DISCOUNTS ------------------------------ */
/**
 * Calculate Total Discount Amount (Not including NSW Voucher)
 *
 * @param {Object} rego
 * @param {Object[]} regos
 * @return {Number} total
 */
export const totalDiscounts = (rego, regos) =>
  Object.entries(rego.priceItem.pricing).reduce((acc, [levelName, regoLevel]) => {
    let familyDiscount = 0;
    if (
      levelName === "club" &&
      rego.priceItem.pricing.club &&
      rego.priceItem.pricing.club.familyDiscounts
    ) {
      const applicableRegos =
        regos
          .slice(0, regos.indexOf(rego))
          .filter((r) => r.entity._id === rego.entity._id && r.type === rego.type).length + 1;
      const applicableDiscount = rego.priceItem.pricing.club.familyDiscounts.find(
        (d) =>
          d.memberType === rego.type &&
          d.familyFrom <= applicableRegos &&
          d.familyTo >= applicableRegos
      );
      if (applicableDiscount) familyDiscount = applicableDiscount.amount;
    }
    const { discount } = regoLevel;
    const regularDiscount =
      discount && discount.calculatedAmount > 0 ? discount.calculatedAmount : 0;
    const fullDiscount = familyDiscount + regularDiscount;
    const adjustedDiscount =
      regoLevel.price - fullDiscount >= 0 ? acc + fullDiscount : acc + regoLevel.price;
    return adjustedDiscount;
  }, 0);

/**
 * Calculate Total Discount Amount (Not including NSW Voucher)
 *
 * @param {Object} rego
 * @param {Object[]} regos
 * @return {Number} total
 */
export const totalDiscountsLevel = (rego, regos, levelName) => {
  let familyDiscount = 0;
  if (
    levelName === "club" &&
    rego.priceItem.pricing.club &&
    rego.priceItem.pricing.club.familyDiscounts
  ) {
    const applicableRegos =
      regos
        .slice(0, regos.indexOf(rego))
        .filter((r) => r.entity._id === rego.entity._id && r.type === rego.type).length + 1;
    const applicableDiscount = rego.priceItem.pricing.club.familyDiscounts.find(
      (d) =>
        d.memberType === rego.type &&
        d.familyFrom <= applicableRegos &&
        d.familyTo >= applicableRegos
    );
    if (applicableDiscount) familyDiscount = applicableDiscount.amount;
  }
  const { discount } = rego.priceItem.pricing[levelName];
  const regularDiscount = discount && discount.calculatedAmount > 0 ? discount.calculatedAmount : 0;
  const fullDiscount = familyDiscount + regularDiscount;
  const adjustedDiscount =
    rego.priceItem.pricing[levelName].price - fullDiscount >= 0
      ? fullDiscount
      : rego.priceItem.pricing[levelName].price;
  return adjustedDiscount;
};

/**
 * Calculate Voucher Discount Amount - the min of club amount / discount balance
 *
 * @param {*} rego
 * @return {Number} voucher discount total
 */
export const activeKidsVoucherDiscounts = (rego, regos) => {
  const { activeKidsVoucher = [] } = rego;
  const { pricing } = rego.priceItem;
  const balance = activeKidsVoucher.reduce(
    (accumulator, { balance }) => accumulator + balance,
    0,
  )

  // Hook to check for club pricing && club price > 0 || saleableItems
  if (
    rego.entity.entityType === "club" &&
    balance > 0 &&
    pricing.club &&
    (pricing.club.price > 0 || pricing.club.saleableItems.length > 0)
  ) {
    let clubPrice = pricing.club.price;
    // Add saleableItems to clubPrice
    pricing.club.saleableItems.forEach((item) => {
      const { qty, price } = item;
      if (qty && qty > 0) clubPrice += qty * price;
    });
    // Deduct discounts to clubPrice
    const discount = totalDiscountsLevel(rego, regos, "club");
    if (discount && discount > 0) clubPrice -= discount;
    // Return highest amount
    return clubPrice > balance ? balance : clubPrice;
  }
  // Programs
  if (
    rego.entity.entityType === "program" &&
    balance > 0 &&
    pricing.program &&
    pricing.program.price > 0
  ) {
    const programPrice = pricing.program.price;
    return programPrice > balance ? balance : programPrice;
  }

  // Teams
  if (rego.entity.entityType === "team" && balance > 0) {
    let teamPrice = 0;

    if (
      (pricing.competition && pricing.competition.price > 0) ||
      (pricing.competition.saleableItems && pricing.competition.saleableItems.length > 0)
    ) {
      if (pricing.competition && pricing.competition.price) teamPrice += pricing.competition.price;

      if (
        pricing.competition &&
        pricing.competition.saleableItems &&
        pricing.competition.saleableItems.length > 0
      ) {
        pricing.competition.saleableItems.forEach((item) => {
          const { qty, price } = item;
          if (qty && qty > 0) teamPrice += qty * price;
        });
      }
    }

    if (
      (pricing.association && pricing.association.price > 0) ||
      (pricing.association &&
        pricing.association.saleableItems &&
        pricing.association.saleableItems.length > 0)
    ) {
      if (pricing.association && pricing.association.price) teamPrice += pricing.association.price;

      if (
        pricing.association &&
        pricing.association.saleableItems &&
        pricing.association.saleableItems.length > 0
      ) {
        pricing.association.saleableItems.forEach((item) => {
          const { qty, price } = item;
          if (qty && qty > 0) teamPrice += qty * price;
        });
      }
    }

    return teamPrice > balance ? balance : teamPrice;
  }
  return 0;
};

export const programSettingsDiscountVoucherDiscounts = (rego) => {
  const { programSettingsDiscountVoucher: { balance } = { balance: 0 } } = rego;
  const { pricing } = rego.priceItem;
  // Hook to check for club pricing && club price > 0 || saleableItems
  if (
    balance > 0 &&
    pricing.program &&
    (pricing.program.price > 0 || pricing.program.saleableItems.length > 0)
  ) {
    return balance;
  }
  return 0;
};

export const programDiscountVoucherDiscounts = (rego) => {
  const { programDiscountVoucher: { balance } = { balance: 0 } } = rego;
  const { pricing } = rego.priceItem;
  // Hook to check for club pricing && club price > 0 || saleableItems
  if (
    balance > 0 &&
    pricing.program &&
    (pricing.program.price > 0 || pricing.program.saleableItems.length > 0)
  ) {
    return balance;
  }
  return 0;
};

export const clubDiscountVoucherDiscounts = (rego, regos) => {
  const { clubDiscountVoucher: { balance } = { balance: 0 } } = rego;
  const { pricing } = rego.priceItem;
  // Hook to check for club pricing && club price > 0 || saleableItems
  if (
    balance > 0 &&
    pricing.club &&
    (pricing.club.price > 0 || pricing.club.saleableItems.length > 0)
  ) {
    // eslint-disable-next-line no-unused-vars
    let clubPrice = pricing.club.price;
    // Add saleableItems to clubPrice
    pricing.club.saleableItems.forEach((item) => {
      const { qty, price } = item;
      if (qty && qty > 0) clubPrice += qty * price;
    });
    // Deduct discounts to clubPrice
    const discount = totalDiscountsLevel(rego, regos, "club");
    if (discount && discount > 0) clubPrice -= discount;
    // * For TFA in one fee they don't want to apply active kids voucher discount if a member is registering to the team
    if (
      rego.entity.orgtree.national._id === 31 &&
      rego.entity.entityType === "team" &&
      balance > 0
    ) {
      return clubPrice > balance ? balance : clubPrice;
    }
    const activeBalance = rego.activeKidsVoucher ? rego.activeKidsVoucher.balance : 0;
    if (activeBalance && activeBalance > 0) clubPrice -= activeBalance;
    // Return highest amount
    return clubPrice > balance ? balance : clubPrice;
  }
  return 0;
};

export const associationDiscountVoucherDiscounts = (rego, regos) => {
  const { associationDiscountVoucher: { balance } = { balance: 0 } } = rego;
  const { pricing } = rego.priceItem;
  // Hook to check for club pricing && club price > 0 || saleableItems
  let associationPrice = 0;
  let competitionPrice = 0;
  if (pricing.association && pricing.association.price > 0)
    associationPrice += pricing.association.price;
  if (pricing.competition && pricing.competition.price > 0)
    competitionPrice += pricing.competition.price;
  if (
    pricing.association &&
    pricing.association.saleableItems &&
    pricing.association.saleableItems.length > 0
  ) {
    pricing.association.saleableItems.forEach((item) => {
      const { qty, price } = item;
      if (qty && qty > 0) associationPrice += qty * price;
    });
  }

  if (
    pricing.competition &&
    pricing.competition.saleableItems &&
    pricing.competition.saleableItems.length > 0
  ) {
    pricing.competition.saleableItems.forEach((item) => {
      const { qty, price } = item;
      if (qty && qty > 0) competitionPrice += qty * price;
    });
  }

  if (rego.associationDiscountVoucher) {
    // eslint-disable-next-line no-unused-vars

    let totalPrice = associationPrice + competitionPrice;

    // Deduct discounts to clubPrice
    let discount = rego.associationDiscountVoucher ? rego.associationDiscountVoucher.balance : 0; // say 60
    discount < totalPrice ? discount : (discount = totalPrice);

    // Return highest amount
    return discount;
  }
  return 0;
};
/* ------------------------- END DISCOUNTS ------------------------------ */

/**
 * CHECKOUT: Calculate Total Registration Amount (Not including NSW Voucher)
 *
 * @param {*} regos
 * @return {Number} total
 */
export const totalRegistrationsCheckout = (regos) => {
  let total = 0;
  regos.forEach((rego) => {
    // if (rego.priceItem.onlinePaymentRequired)
    total += rego.priceItem.totalPrice;
  });
  return total;
};

/**
 * CHECKOUT: Calculate Total Checkout Amount
 *
 * @param {*} regos
 * @return {Number} total
 */
export const totalCheckout = (regos) => {
  let ultimateTotal = 0;
  // this has different rules if it is NRL or touch
  regos.forEach((rego) => {
    let total = 0;
    if (!rego) return;
    const { club, competition, association, region, state, national, program } =
      rego.priceItem.pricing;

    if (competition && competition.price) total += competition.price;
    total += totalSaleables(competition);
    if (program && program.price) total += program.price;
    total += totalSaleables(program);
    const rugbyCode = rego.entity.orgtree.national._id; // 32 NRL 21 TFA
    //  discounts behave different between NRL and TFA
    if (rugbyCode === 32) {
      // apply discounts
      if (club && club.price) total += club.price;
      total += totalSaleables(club);
      const voucherDiscount = getTotalDiscounts(rego);
      const orgDiscounts = totalDiscounts(rego, regos);
      total -= voucherDiscount;
      total -= orgDiscounts;
      total = Math.max(0, total); // min 0
      if (association && association.price) total += association.price;
      total += totalSaleables(association);
    }
    if (rugbyCode === 31) {
      if (association && association.price) total += association.price;
      total += totalSaleables(association);
      // apply discounts
      const voucherDiscount = getTotalDiscounts(rego);
      total -= totalDiscounts(rego, regos);
      // * ONE FEE - remove club discount from total, will be added separately with club check below
      if (rego.clubDiscountVoucher) total += rego.clubDiscountVoucher.balance;
      total -= voucherDiscount;
      total = Math.max(0, total); // min 0
      if (club && club.price) {
        total += club.price;
        // add club discount here
        if (rego.clubDiscountVoucher) total -= rego.clubDiscountVoucher.balance;
      }
      total += totalSaleables(club);
    }

    if (region && region.price) total += region.price;
    total += totalSaleables(region);
    if (state && state.price) total += state.price;
    total += totalSaleables(state);
    if (national && national.price) total += national.price;
    total += totalSaleables(national);
    ultimateTotal += total;
  });
  return ultimateTotal;
};
const reducer = (accumulator, currentValue) => {
  if (currentValue.qty && currentValue.price)
    return accumulator + currentValue.qty * currentValue.price;
  return accumulator;
};
const totalSaleables = (pricing) => {
  if (pricing && pricing.saleableItems && pricing.saleableItems.length > 0) {
    return pricing.saleableItems.reduce(reducer, 0);
  } else {
    return 0;
  }
};
const getTotalDiscounts = (rego) => {
  const {
    activeKidsVoucher,
    additionalDetails,
    associationDiscountVoucher,
    clubDiscountVoucher,
    programSettingsDiscountVoucher,
    programDiscountVoucher,
  } = rego;
  let discount = 0;
  if (activeKidsVoucher && Array.isArray(activeKidsVoucher)) {
    const balance = activeKidsVoucher.reduce(
      (accumulator, { balance }) => accumulator + balance,
      0,
    )
    discount += balance;
  }
  if (programSettingsDiscountVoucher && programSettingsDiscountVoucher.balance)
    discount += programSettingsDiscountVoucher.balance;
  if (programDiscountVoucher && programDiscountVoucher.balance)
    discount += programDiscountVoucher.balance;
  if (associationDiscountVoucher && associationDiscountVoucher.balance)
    discount += associationDiscountVoucher.balance;
  if (clubDiscountVoucher && clubDiscountVoucher.balance) discount += clubDiscountVoucher.balance;
  return discount;
};

export const totalCheckoutLegacy = (regos) => {
  let total = 0;
  regos.forEach((rego) => {
    //if (rego.priceItem.onlinePaymentRequired)
    total += rego.priceItem.totalPrice;
    total -= totalDiscounts(rego, regos);
    // this extracts the price and qty from the rego priceItem.pricing array. terrible data design
    getAllAddonsForRego(rego).forEach((addon) => {
      if (addon.qty && addon.price) {
        total += addon.qty * addon.price;
      }
    });

    const clubDiscountAmount = clubDiscountVoucherDiscounts(rego, regos);
    const associationDiscountAmount = associationDiscountVoucherDiscounts(rego, regos);
    const activeKidDiscountAmount = activeKidsVoucherDiscounts(rego, regos);
    total -= clubDiscountAmount;
    total -= associationDiscountAmount;
    total -= activeKidDiscountAmount;
    // Set total < 0 ? 0
    total = Math.max(0, total);
  });
  return total;
};
