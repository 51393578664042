<template>
  <div>
    <p class="headings tw-text-xl tw-font-extrabold">
      NRL - COMMUNITY HEAD INJURY RECOGNITION AND REFERRAL FORM
    </p>
    <h1 class="horizontalSpace headings">A. GENERAL INFORMATION</h1>

    <p class="horizontalSpace">
      Player Name: <span class="box">{{ playerName }}</span>
      <span>
        Age: <span class="box">{{ age }}</span></span
      >
      <span v-if="programName">
        Program: <span class="box">{{ programName }}</span></span
      >
      <span v-else-if="assocName">
        Association: <span class="box">{{ assocName }}</span></span
      >
      <span v-else>
        Club: <span class="box">{{ clubName }}</span></span
      >
    </p>

    <p class="horizontalSpace">
      Examiner Name: <span class="box">{{ firstResponderName }}</span>
      <span>
        Examiner qualifications: <span class="box">{{ firstResponderRole }}</span></span
      >
    </p>
    <p class="horizontalSpace">
      Date: <span class="box">{{ dateOfInj }}</span>
      <span> Half: <span class="box"></span></span>
      <span>
        Approximate Time in Half:
        <span class="box">{{ timeOfInj }}</span></span
      >
      <span> Position of Player: <span class="box"></span></span>
    </p>
    <h1 class="horizontalSpace headings">B. STRUCTURAL HEAD OR NECK INJURY(MUST be completed)</h1>
    <p class="horizontalSpace">
      1. Are there clinical features of a potentially serious or structural head and/or neck injury,
      including prolonged loss of consciousness (>1 minute) requiring urgent and emergency hospital
      transfer?

      <span v-for="(element, index) in yesOrNo" :key="index">
        <input
          type="checkbox"
          :id="element.option"
          :name="element.option"
          class="tw-text-red-600 tw-w-4 tw-h-4"
          :class="
            element.option === 'Yes -Observed' || element.option === 'Yes -Reported'
              ? 'checkboxRed'
              : ''
          "
          :checked="isChecked(element.value, clinicalFeatures)"
          :disabled="true"
        />
        <span
          class="tw-mt-auto tw-mb-1px tw-text-sm tw-leading-3"
          :class="element.option === 'Yes' ? 'tw-text-red-600' : ''"
          >{{ element.option }}
        </span>
      </span>
    </p>
    <h1 class="horizontalSpace headings">C. SIGNS OBSERVED (All fields MUST be completed)</h1>
    <p class="horizontalSpace tw-grid tw-grid-cols-4 tw-gap-5 tw-text-justify">
      <span class="tw-flex-1 tw-w-full tw-font-medium tw-text-red-600">
        Players MUST be removed from a game or training session if any of the following RED FLAGS
        are observed by anyone, including coaches, parents or other players and sent for immediate
        medical assessment.
      </span>

      <span
        class="tw-flex-1 tw-text-center tw-w-1by5 tw-font-medium tw-text-red-600 tw-justify-self-center"
        >YES Observed Directly</span
      >
      <span
        class="tw-flex-1 tw-w-1by5 tw-text-center tw-font-medium tw-text-red-600 tw-justify-self-center"
        >YES Reported</span
      >
      <span
        class="tw-flex-1 tw-w-1by5 tw-text-center tw-font-medium tw-text-black tw-justify-self-center"
        >No</span
      >
    </p>
    <!-- 2 -->
    <p class="horizontalSpace tw-grid tw-grid-cols-4 tw-gap-5 tw-text-justify">
      <span class="tw-flex-1 tw-w-full tw-font-medium tw-text-black"
        >2. Loss of consciousness (or prolonged loss of movement of > 1 &#8212; 2 seconds) or not
        responding appropriately to trainers, referees or other players.
      </span>
      <span v-for="(element, index) in yesNoOptions" :key="index" class="tw-justify-self-center">
        <input
          :id="element.option"
          :name="element.option"
          class="tw-flex-1 tw-text-center tw-font-medium tw-w-4 tw-h-4"
          :class="
            element.option === 'Yes -Observed' || element.option === 'Yes -Reported'
              ? 'tw-border-red-600'
              : ''
          "
          type="checkbox"
          :checked="isChecked(element.value, lossOfConsciousness)"
          :disabled="true"
        />
      </span>
    </p>
    <!-- 3 -->
    <p class="horizontalSpace tw-grid tw-grid-cols-4 tw-gap-5 tw-text-justify">
      <span class="tw-flex-1 tw-w-full tw-font-medium tw-text-black"
        >3. No protective action in fall to ground (not bracing for impact/ floppy or stiff).</span
      >
      <span v-for="(element, index) in yesNoOptions" :key="index" class="tw-justify-self-center">
        <input
          :id="element.option"
          :name="element.option"
          class="tw-flex-1 tw-text-center tw-font-medium tw-w-4 tw-h-4"
          :class="
            element.option === 'Yes -Observed' || element.option === 'Yes -Reported'
              ? 'tw-border-red-600'
              : ''
          "
          type="checkbox"
          :checked="isChecked(element.value, protectiveAction)"
          :disabled="true"
        />
      </span>
    </p>
    <!-- 4 -->
    <p class="horizontalSpace tw-grid tw-grid-cols-4 tw-gap-5 tw-text-justify">
      <span class="tw-flex-1 tw-w-full tw-font-medium tw-text-black"
        >4. Impact seizure/convulsion/fit (stiffening or shaking of arms and/or legs on impact).
      </span>
      <span v-for="(element, index) in yesNoOptions" :key="index" class="tw-justify-self-center">
        <input
          :id="element.option"
          :name="element.option"
          class="tw-flex-1 tw-text-center tw-font-medium tw-w-4 tw-h-4"
          :class="
            element.option === 'Yes -Observed' || element.option === 'Yes -Reported'
              ? 'tw-border-red-600'
              : ''
          "
          type="checkbox"
          :checked="isChecked(element.value, impact)"
          :disabled="true"
        />
      </span>
    </p>
    <!-- 5 -->
    <p class="horizontalSpace tw-grid tw-grid-cols-4 tw-gap-5 tw-text-justify">
      <span class="tw-flex-1 tw-w-full tw-font-medium tw-text-black"
        >5. Balance disturbance (unable to stand steadily or walk unassisted) or clumsy or slow to
        get up following a possible head injury (10-15 seconds).
      </span>
      <span v-for="(element, index) in yesNoOptions" :key="index" class="tw-justify-self-center">
        <input
          :id="element.option"
          :name="element.option"
          class="tw-flex-1 tw-text-center tw-font-medium tw-w-4 tw-h-4"
          :class="
            element.option === 'Yes -Observed' || element.option === 'Yes -Reported'
              ? 'tw-border-red-600'
              : ''
          "
          type="checkbox"
          :checked="isChecked(element.value, balanceDisturbance)"
          :disabled="true"
        />
      </span>
    </p>
    <!-- 6 -->
    <p class="horizontalSpace tw-grid tw-grid-cols-4 tw-gap-5 tw-text-justify">
      <span class="tw-flex-1 tw-w-full tw-font-medium tw-text-black"
        >6. Dazed or blank/vacant stare or not their normal selves/not reacting appropriately to
        surroundings.</span
      >

      <span v-for="(element, index) in yesNoOptions" :key="index" class="tw-justify-self-center">
        <input
          :id="element.option"
          :name="element.option"
          class="tw-flex-1 tw-text-center tw-font-medium tw-w-4 tw-h-4"
          :class="
            element.option === 'Yes -Observed' || element.option === 'Yes -Reported'
              ? 'tw-border-red-600'
              : ''
          "
          type="checkbox"
          :checked="isChecked(element.value, dazedOrBlank)"
          :disabled="true"
        />
      </span>
    </p>
    <!-- 7 -->
    <p class="horizontalSpace tw-grid tw-grid-cols-4 tw-gap-5 tw-text-justify">
      <span class="tw-flex-1 tw-w-full tw-font-medium tw-text-black"
        >7. Unusual behaviour for the player.</span
      >
      <span v-for="(element, index) in yesNoOptions" :key="index" class="tw-justify-self-center">
        <input
          :id="element.option"
          :name="element.option"
          class="tw-flex-1 tw-text-center tw-font-medium tw-w-4 tw-h-4"
          :class="
            element.option === 'Yes -Observed' || element.option === 'Yes -Reported'
              ? 'tw-border-red-600'
              : ''
          "
          type="checkbox"
          :checked="isChecked(element.value, unusualBehaviour)"
          :disabled="true"
        />
      </span>
    </p>
    <!-- 8 -->
    <p class="horizontalSpace tw-grid tw-grid-cols-4 tw-gap-5 tw-text-justify">
      <span class="tw-flex-1 tw-w-full tw-font-medium tw-text-black"
        >8. Confusion or disorientation.</span
      >
      <span v-for="(element, index) in yesNoOptions" :key="index" class="tw-justify-self-center">
        <input
          :id="element.option"
          :name="element.option"
          class="tw-flex-1 tw-text-center tw-font-medium tw-w-4 tw-h-4"
          type="checkbox"
          :checked="isChecked(element.value, confusion)"
          :disabled="true"
        />
      </span>
    </p>
    <!-- 9 -->
    <p class="horizontalSpace tw-grid tw-grid-cols-4 tw-gap-5 tw-text-justify">
      <span class="tw-flex-1 tw-w-full tw-font-medium tw-text-black"
        >9. Memory impairment (e.g. fails Awareness Questions &#8212; refer to CRT6).</span
      >
      <span v-for="(element, index) in yesNoOptions" :key="index" class="tw-justify-self-center">
        <input
          :id="element.option"
          :name="element.option"
          class="tw-flex-1 tw-text-center tw-font-medium tw-w-4 tw-h-4"
          type="checkbox"
          :checked="isChecked(element.value, memoryImpairment)"
          :disabled="true"
        />
      </span>
    </p>
    <!-- 10 -->
    <p class="horizontalSpace tw-grid tw-grid-cols-4 tw-gap-5 tw-text-justify">
      <span class="tw-flex-1 tw-w-full tw-font-medium tw-text-black"
        >10. Player reports or displays any other concussion symptoms (refer to CRT6).</span
      >
      <span v-for="(element, index) in yesNoOptions" :key="index" class="tw-justify-self-center">
        <input
          :id="element.option"
          :name="element.option"
          class="tw-flex-1 tw-text-center tw-font-medium tw-w-4 tw-h-4"
          type="checkbox"
          :checked="isChecked(element.value, playerReports)"
          :disabled="true"
        />
      </span>
    </p>
    <ul class="tw-flex-1 tw-w-full tw-font-medium tw-text-red-600">
      IMPORTANT TO REMEMBER
      <li class="list">
        Any player who is unconscious should be suspected of having a spinal cord injury and treated
        appropriately. This includes DRABCD (Danger, Response, Airway, Breathing, CPR,
        Defibrillation) and they should not be moved unless appropriately trained personnel are
        present.
      </li>
      <li class="list">
        If the player has weakness or tingling/burning in the arms and/or legs, they should be
        treated as if they have a spinal injury and an ambulance called.
      </li>
      <li class="list">
        A player with a facial injury after head trauma should be assessed for signs and symptoms of
        concussion
      </li>
      <li class="list">
        Players must be HONEST in reporting how they feel. Uncooperative behaviour by players should
        be considered a possible sign of concussion and result in their removal from play as a
        potential head injury.
      </li>
    </ul>
    <p class="horizontalSpace tw-text-sm tw-text-black">
      <sup>1</sup>Refer to the NRL Concussion Management Guidelines on the Play NRL website:
      <a
        href="https://www.playrugbyleague.com/trainer/concussion/"
        class="tw-text-blue-600 tw-underline"
        >playrugbyleague.com/concussion</a
      >
      and use the Concussion Recognition Tool 6 (CRT6)<a
        href=" https://bjsm.bmj.com/content/bjsports/57/11/692.full.pdf"
        class="tw-text-blue-600 tw-underline"
        >https://bjsm.bmj.com/content/bjsports/57/11/692.full.pdf</a
      >
    </p>
    <p class="tw-text-sm tw-text-black">
      <sup>2</sup>NOTE: 'Balance disturbance' is defined as when a Player is unable to stand
      steadily unassisted or walk normally and steadily without support in the context of a possible
      head injury.
    </p>

    <h1 class="horizontalSpace headings">D. OUTCOME AND ACTION (MUST be completed)</h1>
    <div class="tw-grid tw-grid-cols-4 tw-gap-2 horizontalSpace">
      <p class="tw-text-sm tw-text-black tw-col-span-3 tw-m-0">
        If 'Yes' is selected for question 1, an ambulance must be called for immediate transfer to
        hospital
      </p>

      <input
        type="checkbox"
        class="tw-font-medium tw-w-4 tw-h-4 tw-justify-self-center tw-m-auto"
        v-model="ambulance"
        disabled
      />

      <p class="tw-text-sm tw-text-black tw-col-span-3 tw-m-0">
        If Yes is selected for any of questions 2-10, immediate removal from play and medical
        assessment<sup>3</sup> are required
      </p>
      <input
        type="checkbox"
        class="tw-font-medium tw-w-4 tw-h-4 tw-justify-self-center tw-m-auto"
        v-model="removal"
        disabled
      />
    </div>

    <p class="tw-text-black tw-font-bold">
      A PLAYER SUSPECTED OF HAVING SUSTAINED A CONCUSSION MUST
      <span class="tw-underline">NOT</span> BE ALLOWED TO RETURN TO PLAY IN THE SAME GAME OR
      TRAINING (OR ANY GAME OR TRAINING) EVEN IF THE SYMPTOMS RESOLVE. THE PLAYER MUST BE SENT FOR
      MEDICAL ASSESSMENT IN THE CARE OF A RESPONSIBLE ADULT.
    </p>

    <p class="headings tw-text-xl tw-font-extrabold tw-mt-4">
      NRL - COMMUNITY HEAD INJURY RECOGNITION AND REFERRAL FORM
    </p>
    <p class="horizontalSpace">
      Player Name: <span class="box">{{ playerName }}</span>
      <span>
        Date: <span class="box">{{ dateOfInj }}</span></span
      >
    </p>

    <h1 class="horizontalSpace headings">
      E. SYMPTOM RECORD &#8212;
      <span class="tw-font-normal tw-text-sm"
        >complete <span class="tw-font-bold tw-underline">ALL FIELDS</span> below based on how the
        player feels <span class="tw-font-bold">now.</span> (Helpful for medical follow up.)
      </span>
    </h1>
    <p class="tw-font-bold">
      A Parent should help answer these questions if the Player is 12 years old or younger
    </p>

    <div class="tw-grid tw-grid-cols-8 tw-gap-5 tw-border-2">
      <div class="tw-border-r-2 tw-flex">
        <p class="tw-flex-1 tw-w-full tw-font-medium tw-text-black"></p>
      </div>
      <div class="tw-border-r-2">
        <p
          class="tw-flex-1 tw-w-full tw-font-medium tw-text-black tw-flex tw-justify-center tw-items-center"
        >
          None
        </p>
      </div>
      <div class="tw-border-r-2 tw-col-span-2">
        <p
          class="tw-flex-1 tw-w-full tw-font-medium tw-text-black tw-flex tw-justify-center tw-items-center"
        >
          Mild
        </p>
      </div>
      <div class="tw-border-r-2 tw-col-span-2">
        <p
          class="tw-flex-1 tw-w-full tw-font-medium tw-text-black tw-flex tw-justify-center tw-items-center"
        >
          Moderate
        </p>
      </div>
      <div class="tw-border-r-2 tw-col-span-2">
        <p
          class="tw-flex-1 tw-w-full tw-font-medium tw-text-black tw-col-span-2 tw-flex tw-justify-center tw-items-center"
        >
          Severe
        </p>
      </div>
    </div>

    <div
      v-for="symp in questions"
      :key="symp.symptom"
      class="tw-grid tw-grid-cols-8 tw-gap-5 tw-border-2"
    >
      <div class="tw-border-r-2 tw-flex">
        <p class="tw-flex-1 tw-font-medium tw-text-black tw-flex tw-m-auto">
          {{ symp.label }}
        </p>
      </div>

      <div class="tw-border-r-2 tw-flex tw-justify-center tw-items-center">
        <div class="tw-m-auto">
          <p
            class="tw-flex-1 tw-font-medium tw-text-black tw-flex tw-justify-center tw-items-center tw-m-auto"
            :class="
              symp.step === 0
                ? 'tw-text-black tw-w-6 tw-h-6 tw-rounded-full tw-border-2 tw-border-red-600 tw-flex tw-justify-center tw-items-center '
                : ''
            "
          >
            0
          </p>
        </div>
      </div>
      <div class="tw-border-r-2 tw-flex tw-justify-center tw-items-center">
        <div class="tw-m-auto">
          <p
            class="tw-flex-1 tw-font-medium tw-text-black tw-flex tw-justify-center tw-items-center tw-m-auto"
            :class="
              symp.step === 1
                ? 'tw-text-black tw-w-6 tw-h-6 tw-rounded-full tw-border-2 tw-border-red-600 tw-flex tw-justify-center tw-items-center '
                : ''
            "
          >
            1
          </p>
        </div>
      </div>
      <div class="tw-border-r-2 tw-flex tw-justify-center tw-items-center">
        <div class="tw-m-auto">
          <p
            class="tw-flex-1 tw-font-medium tw-text-black tw-flex tw-justify-center tw-items-center tw-m-auto"
            :class="
              symp.step === 2
                ? 'tw-text-black tw-w-6 tw-h-6 tw-rounded-full tw-border-2 tw-border-red-600 tw-flex tw-justify-center tw-items-center '
                : ''
            "
          >
            2
          </p>
        </div>
      </div>
      <div class="tw-border-r-2 tw-flex tw-justify-center tw-items-center">
        <div class="tw-m-auto">
          <p
            class="tw-flex-1 tw-font-medium tw-text-black tw-flex tw-justify-center tw-items-center tw-m-auto"
            :class="
              symp.step === 3
                ? 'tw-text-black tw-w-6 tw-h-6 tw-rounded-full tw-border-2 tw-border-red-600 tw-flex tw-justify-center tw-items-center '
                : ''
            "
          >
            3
          </p>
        </div>
      </div>
      <div class="tw-border-r-2 tw-flex tw-justify-center tw-items-center">
        <div class="tw-m-auto">
          <p
            class="tw-flex-1 tw-font-medium tw-text-black tw-flex tw-justify-center tw-items-center tw-m-auto"
            :class="
              symp.step === 4
                ? 'tw-text-black tw-w-6 tw-h-6 tw-rounded-full tw-border-2 tw-border-red-600 tw-flex tw-justify-center tw-items-center '
                : ''
            "
          >
            4
          </p>
        </div>
      </div>
      <div class="tw-border-r-2 tw-flex tw-justify-center tw-items-center">
        <div class="tw-m-auto">
          <p
            class="tw-flex-1 tw-font-medium tw-text-black tw-flex tw-justify-center tw-items-center tw-m-auto"
            :class="
              symp.step === 5
                ? 'tw-text-black tw-w-6 tw-h-6 tw-rounded-full tw-border-2 tw-border-red-600 tw-flex tw-justify-center tw-items-center '
                : ''
            "
          >
            5
          </p>
        </div>
      </div>
      <div class="tw-border-r-2 tw-flex tw-justify-center tw-items-center">
        <div class="tw-m-auto">
          <p
            class="tw-flex-1 tw-font-medium tw-text-black tw-flex tw-justify-center tw-items-center tw-m-auto"
            :class="
              symp.step === 6
                ? 'tw-text-black tw-w-6 tw-h-6 tw-rounded-full tw-border-2 tw-border-red-600 tw-flex tw-justify-center tw-items-center '
                : ''
            "
          >
            6
          </p>
        </div>
      </div>
    </div>
    <p>
      If you know the player (or ask parents/friends), how different is the player acting compared
      to his/her usual self?
    </p>

    <div
      class="tw-grid tw-grid-cols-4 tw-border-2 tw-justify-center tw-items-center tw-w-3by5 tw-mx-auto fillBackground"
    >
      <div
        class="tw-border-r-2"
        :class="playerSelfState === 'No different' ? 'tw-bg-gray-400 ' : ' '"
      >
        <p class="tw-flex tw-justify-center tw-items-center">No different</p>
      </div>
      <div
        class="tw-border-r-2"
        :class="playerSelfState === 'Very different' ? ' tw-bg-gray-400 ' : ''"
      >
        <p class="tw-flex tw-justify-center tw-items-center">Very different</p>
      </div>
      <div class="tw-border-r-2" :class="playerSelfState === 'Unsure' ? 'tw-bg-gray-400 ' : ''">
        <p class="tw-flex tw-justify-center tw-items-center">Unsure</p>
      </div>
      <div
        class="tw-border-r-2"
        :class="playerSelfState === 'Not applicable' ? 'tw-bg-gray-400 ' : ''"
      >
        <p class="tw-flex tw-justify-center tw-items-center">N/A</p>
      </div>
    </div>

    <p class="tw-font-bold horizontalSpace tw-text-sm">SIGNATURE OF FIRST RESPONDER</p>
    <p class="tw-w-full">
      Signed:
      <input type="text" class="box tw-flex-1" id="sign" v-model="firstResponderName" disabled />
      Date: <input type="text" class="box tw-flex-1" disabled v-model="dateOfInj" /> Time Completed:
      <input type="text" class="box tw-flex-1" disabled v-model="timeOfInj" />
    </p>
    <p class="tw-text-red-600 tw-mt-3">
      The NRL require the injured player to be assessed by a
      <span class="tw-font-bold">Doctor</span
      ><span class="tw-underline"> as soon as possible</span> after a head injury
      <span class="tw-font-bold">PRIOR</span> to commencing a Return to Sport Strategy.
    </p>
    <p class="tw-text-blue-500">
      <span class="tw-underline tw-font-bold">POST CONCUSSION INJURY ADVICE</span> &#8212; for
      person monitoring the injured player
    </p>
    <ul class="tw-text-blue-500 tw-list-disc">
      <li>
        Recovery time from concussion is variable and signs and symptoms can evolve over minutes or
        hours
      </li>
      <li>
        If the player displays ANY of the following: vomiting, neck pain, severe or worsening
        headache, double vision, excessive drowsiness, convulsions, change in behaviour, loss of
        consciousness or weakness/tingling/burning in arms or legs; then call an ambulance or
        contact your doctor or the nearest emergency department
        <span class="tw-font-bold tw-underline">immediately</span>
      </li>
      <li>
        Rest (physical and mental) - including any training until medically cleared (at least 24-48
        hrs)
      </li>
      <li><span class="tw-text-red-600">NO</span> alcohol until medically cleared</li>
      <li><span class="tw-text-red-600">NO</span> driving until medically cleared</li>
      <li>
        <span class="tw-text-red-600">NO</span> medications such as sleeping pills, aspirin,
        anti-inflammatories or sedating/strong pain killers
      </li>
      <li class="tw-font-bold">
        <span class="tw-underline"
          >You MUST take this completed form to your doctor to assist with the assessment</span
        >
        &#8212; it is recommended that you book a long consultation with your doctor
      </li>
    </ul>
    <p class="tw-text-red-600 tw-text-center">
      A <span class="tw-font-bold">final</span> consultation by a doctor, to clear the player
      medically fit <span class="tw-font-bold">before</span> unrestricted training and match play,
      <span class="tw-font-bold tw-underline">MUST</span> be undertaken.
    </p>
    <p class="tw-font-bold tw-text-center">
      It is preferable that the same doctor performs all the assessments (including initial and
      final clearance).
    </p>
    <p class="tw-mt-4 tw-underline tw-font-extrabold tw-text-xl">
      NRL Community Head Injury/Concussion Medical Clearance
    </p>
    <p class="tw-mt-5">
      The NRL takes player welfare and safety including concussion seriously. Any player removed
      from the field of play with a suspected concussion MUST be assessed by a medical practitioner
      (doctor) as soon as possible to determine if they have sustained a concussion or not. If the
      player has been assessed and it is determined that they have sustained a concussion, they MUST
      follow the Return to Sport (RTS) Strategy for their age and undergo a final consultation to
      clear the player medically fit before resuming unrestricted training and match play. It is
      strongly recommended that the same medical practitioner (doctor) performs all the assessments
      on a player for each head injury/concussion episode/presentation.
    </p>
    <p class="tw-mt-3">
      Your role as the treating Medical Practitioner (doctor) is to assess the player and guide
      their Return to Sport strategy if they are deemed to have sustained a concussion. Detailed
      information for you as the treating Medical Practitioner (doctor) can be found at
      <a
        href="https://support.playrugbyleague.com/hc/en-us/articles/8226944381071-Medical-Professionals-and-the-assessment-and-treatment-of-concussion-in-Rugby-League"
        class="tw-text-blue-600 tw-underline"
        >Medical Professionals and the assessment and treatment of concussion in Rugby League</a
      >
    </p>
    <p class="tw-mt-3 tw-font-bold tw-underline">Minimum timeframes to Return to Sport (RTS)</p>
    <ul class="tw-mt-3 tw-list-disc tw-text-black tw-ml-4">
      <li>
        <span class="tw-font-bold">Adults (19 years and over)</span>: 11 days. The earliest a player
        can be made available for team selection is on the 11th day after sustaining a concussion
        and only after all symptoms have resolved and the player has been cleared to play by a
        medical professional.
        <span class="tw-font-italics"> Please note: The date of injury is day 0.</span>
      </li>
      <li>
        <span class="tw-font-bold"> Children and adolescents (18 years and younger)</span>: 19 days.
        The earliest a player can be made available for team selection is on the 19th day after
        sustaining a concussion and only after all symptoms have resolved and the player has been
        cleared to play by a medical professional.
        <span class="tw-font-italics"> Please note: The date of injury is day 0.</span>
      </li>
    </ul>
    <div class="tw-mt-5 tw-border tw-border-black"></div>
    <p class="tw-mt-4 tw-font-bold tw-underline">
      INITIAL ASSESSMENT &#8212; Player CLEARED of Concussion:
      <span
        >To be completed by the treating
        <span class="tw-font-bold"> Medical Practitioner (doctor):</span>
      </span>
    </p>
    <p class="tw-mt-3">
      I have cited the signs and symptoms record (pg. 1 and 2 of this form)
      <span v-for="(element, index) in yesOrNo" :key="index" class="tw-justify-self-center"
        >{{ element.option }}
        <input
          :id="element.option"
          :name="element.option"
          class="tw-flex-1 tw-text-center tw-font-medium tw-w-4 tw-h-4"
          :class="element.option === 'Yes' ? 'tw-border-red-600' : ''"
          type="checkbox"
          :checked="isChecked(element.value, impact)"
          :disabled="true"
        />
      </span>
      and examined.........................................................following a head injury
      sustained on.............................Based on my assessment I declare them medically fit
      to return to unrestricted training and match play.
    </p>
    <div class="tw-mt-3 tw-flex tw-flex-wrap tw-w-full adjustHeight">
      <div class="tw-w-3by4 tw-flex tw-flex-wrap">
        <label class="tw-pr-3 tw-w-1by6 tw-py">Practitioner Name</label>
        <span>.........................................................</span>
        <label class="tw-pr-3 tw-py">Date</label>
        <span>...................................</span>

        <div class="tw-w-full tw-flex tw-flex-wrap tw-mt-0">
          <label class="tw-pr-3 tw-w-1by6 tw-py">Signed</label>
          <span>.........................................................</span>
        </div>
      </div>
      <div class="tw-w-1by4">
        <textarea
          class="tw-border-2 tw-border-black tw-bg-gray-200 tw-h-24 tw-w-full tw-text-center"
          placeholder="Medical Practice Stamp"
        ></textarea>
      </div>
    </div>
    <div class="tw-mt-5 tw-border tw-border-black"></div>
    <p class="tw-mt-4 tw-font-bold tw-underline">
      INITIAL ASSESSMENT &#8212; Player DIAGNOSED with Concussion:
      <span
        >To be completed by the treating
        <span class="tw-font-bold"> Medical Practitioner (doctor):</span>
      </span>
    </p>
    <p class="tw-mt-3">
      I have cited the signs and symptoms record (pg. 1 and 2 of this form)
      <span v-for="(element, index) in yesOrNo" :key="index" class="tw-justify-self-center"
        >{{ element.option }}
        <input
          :id="element.option"
          :name="element.option"
          class="tw-flex-1 tw-text-center tw-font-medium tw-w-4 tw-h-4"
          :class="element.option === 'Yes' ? 'tw-border-red-600' : ''"
          type="checkbox"
          :checked="isChecked(element.value, impact)"
          :disabled="true"
        />
      </span>
      and examined.........................................................following a head injury
      sustained on.............................Based on my assessment I confirm that they have
      sustained a concussion.
    </p>
    <div class="tw-mt-3 tw-flex tw-flex-wrap tw-w-full adjustHeight">
      <div class="tw-w-3by4 tw-flex tw-flex-wrap">
        <label class="tw-pr-3 tw-w-1by6 tw-py">Practitioner Name</label>
        <span>.........................................................</span>
        <label class="tw-pr-3 tw-py">Date</label>
        <span>...................................</span>

        <div class="tw-w-full tw-flex tw-flex-wrap tw-mt-0">
          <label class="tw-pr-3 tw-w-1by6 tw-py">Signed</label>
          <span>.........................................................</span>
        </div>
      </div>
      <div class="tw-w-1by4 tw-w-full">
        <textarea
          class="tw-border-2 tw-border-black tw-bg-gray-200 tw-h-24 tw-w-full tw-text-center"
          placeholder="Medical Practice Stamp"
        ></textarea>
      </div>
    </div>
    <div class="tw-mt-5 tw-border tw-border-black"></div>
    <p class="tw-mt-4 tw-font-bold tw-underline">
      FOLLOW UP ASSESSMENT &#8212; Player Cleared to Play following Concussion:
      <span
        >To be completed by the treating
        <span class="tw-font-bold"> Medical Practitioner (doctor):</span>
      </span>
    </p>
    <p class="tw-mt-3">
      I have cited the signs and symptoms record (pg. 1 and 2 of this form)
      <span v-for="(element, index) in yesOrNo" :key="index" class="tw-justify-self-center"
        >{{ element.option }}
        <input
          :id="element.option"
          :name="element.option"
          class="tw-flex-1 tw-text-center tw-font-medium tw-w-4 tw-h-4"
          :class="element.option === 'Yes' ? 'tw-border-red-600' : ''"
          type="checkbox"
          :checked="isChecked(element.value, impact)"
          :disabled="true"
        />
      </span>
      and examined.........................................................and based on my
      assessment I declare them medically fit to return to unrestricted training and match play
      following the head injury sustained on..............................
    </p>
    <div class="tw-mt-3 tw-flex tw-flex-wrap tw-w-full adjustHeight">
      <div class="tw-w-3by4 tw-flex tw-flex-wrap">
        <label class="tw-pr-3 tw-w-1by6 tw-py">Practitioner Name</label>
        <span>.........................................................</span>
        <label class="tw-pr-3 tw-py">Date</label>
        <span>...................................</span>

        <div class="tw-w-full tw-flex tw-flex-wrap tw-mt-0">
          <label class="tw-pr-3 tw-w-1by6 tw-py">Signed</label>
          <span>.........................................................</span>
        </div>
      </div>
      <div class="tw-w-1by4">
        <textarea
          class="tw-border-2 tw-border-black tw-bg-gray-200 tw-h-24 tw-w-full tw-text-center"
          placeholder="Medical Practice Stamp"
        ></textarea>
      </div>
    </div>
  </div>
</template>

<script>
import { calculateAge } from "@/utils/date/minor";
import { yesOrNo, yesNoOptions } from "../../utils/constants/variables";
import { msg } from "@/utils/constants/msg";
export default {
  data() {
    return {
      yesOrNo,
      yesNoOptions,

      questions: [
        {
          symptom: "headache",
          label: "Headache",
          stepName: "None",
          step: 0,
        },
        {
          symptom: "pressureInHead",
          label: `“Pressure in head”`,
          stepName: "None",
          step: 0,
        },
        {
          symptom: "neckPain",
          label: "Neck Pain",
          stepName: "None",
          step: 0,
        },
        {
          symptom: "nauseaOrVomit",
          label: "Nausea or vomiting",
          stepName: "None",
          step: 0,
        },
        {
          symptom: "dizzy",
          label: "Dizziness",
          stepName: "None",
          step: 0,
        },
        {
          symptom: "blurVision",
          label: "Blurred vision",
          stepName: "None",
          step: 0,
        },
        {
          symptom: "balanceProblem",
          label: "Balance problems",
          stepName: "None",
          step: 0,
        },
        {
          symptom: "lightSensitivity",
          label: "Sensitivity to light",
          stepName: "None",
          step: 0,
        },
        {
          symptom: "noiseSensitivity",
          label: "Sensitivity to noise",
          stepName: "None",
          step: 0,
        },
        {
          symptom: "slowedDown",
          label: "Feeling slowed down",
          stepName: "None",
          step: 0,
        },
        {
          symptom: "inAFog",
          label: "Feeling like “in a fog”",
          stepName: "None",
          step: 0,
        },
        {
          symptom: "notFeelRight",
          label: "“Don't feel right”",
          stepName: "None",
          step: 0,
        },
        {
          symptom: "difficultyInConcentrating",
          label: "Difficulty concentrating ",
          stepName: "None",
          step: 0,
        },
        {
          symptom: "difficultyRemembering",
          label: "Difficulty remembering",
          stepName: "None",
          step: 0,
        },
        {
          symptom: "fatigued",
          label: "Fatigue or low energy",
          stepName: "None",
          step: 0,
        },
        {
          symptom: "confused",
          label: "Confusion",
          stepName: "None",
          step: 0,
        },
        {
          symptom: "drowsy",
          label: "Drowsiness",
          stepName: "None",
          step: 0,
        },
        {
          symptom: "emotional",
          label: "More emotional",
          stepName: "None",
          step: 0,
        },
        {
          symptom: "irritable",
          label: "Irritability",
          stepName: "None",
          step: 0,
        },
        {
          symptom: "sad",
          label: "Sadness",
          stepName: "None",
          step: 0,
        },
        {
          symptom: "nervousOrAnxious",
          label: "Nervous or anxious ",
          stepName: "None",
          step: 0,
        },
        {
          symptom: "asleep",
          label: "Trouble Falling Asleep (If applicable)",
          stepName: "None",
          step: -1,
        },
      ],
      playerSelfState: undefined,
      injury: {
        _id: undefined,
        type: 2,
        scene: undefined,
        status: "Pending",
        isActive: true,
        memberId: undefined,
        meta: {
          competition: {
            _id: undefined,
            name: undefined,
          },
          matchId: undefined,
          teamId: undefined,
        },
        matches: [],
        member: {
          _id: undefined,
          name: "",
        },
        venue: {
          _id: undefined,
          name: "",
          formatted: "",
        },
        dateOfInjuryStr: "",
        timeOfInjuryStr: "",
        dateOfInjury: undefined,
        timeOfInjury: undefined,
        symptons: undefined,
        concussion: {
          clinicalFeatures: undefined,
          lossOfConsciousness: undefined,
          protectiveAction: undefined,
          impact: undefined,
          balanceDisturbance: undefined,
          dazedOrBlank: undefined,
          unusualBehaviour: undefined,
          confusion: undefined,
          memoryImpairment: undefined,
          playerReports: undefined,
        },
        symptomRecord: {
          playerSelfState: "No different",
          headache: { step: 0, stepName: "None" },
          pressureInHead: { step: 0, stepName: "None" },
          neckPain: { step: 0, stepName: "None" },
          nauseaOrVomit: { step: 0, stepName: "None" },
          dizzy: { step: 0, stepName: "None" },
          blurVision: { step: 0, stepName: "None" },
          balanceProblem: { step: 0, stepName: "None" },
          lightSensitivity: { step: 0, stepName: "None" },
          noiseSensitivity: { step: 0, stepName: "None" },
          slowedDown: { step: 0, stepName: "None" },
          inAFog: { step: 0, stepName: "None" },
          notFeelRight: { step: 0, stepName: "None" },
          difficultyInConcentrating: { step: 0, stepName: "None" },
          difficultyRemembering: { step: 0, stepName: "None" },
          fatigued: { step: 0, stepName: "None" },
          confused: { step: 0, stepName: "None" },
          drowsy: { step: 0, stepName: "None" },
          emotional: { step: 0, stepName: "None" },
          irritable: { step: 0, stepName: "None" },
          sad: { step: 0, stepName: "None" },
          nervousOrAnxious: { step: 0, stepName: "None" },
        },
        howPlayerInjured: undefined,
        howPlayerInjuredOther: "",
        treatPlayer: undefined,
        playerReferredTo: undefined,
        playerReferredToOther: "",
        immediateCare: undefined,
        immediateCareOther: "",
        firstResponderName: "",
        firstResponderRole: undefined,
        natureOfInjury: [],
        playerRemoveBy: undefined,
        otherSignificants: "",
        emails: [],
        round: undefined,
      },
      playerName: "",
      clubName: "",
      programName: "",
      assocName: "",
      age: 0,
      firstResponderName: "",
      firstResponderRole: "",
      dateOfInj: "",
      timeOfInj: "",
      ambulance: false,
      removal: false,
      clinicalFeatures: undefined,
      lossOfConsciousness: undefined,
      protectiveAction: undefined,
      impact: undefined,
      balanceDisturbance: undefined,
      dazedOrBlank: undefined,
      unusualBehaviour: undefined,
      confusion: undefined,
      memoryImpairment: undefined,
      playerReports: undefined,
    };
  },

  methods: {
    isChecked(element, field) {
      if (element === field) return "checked";
      else return "";
    },
    formatDateOfInjury(dateOfInjuryStr) {
      if (dateOfInjuryStr) {
        const dt = this.injury.dateOfInjuryStr.split("-");
        return `${dt[2]}/${dt[1]}/${dt[0]}`;
      }
      return "";
    },
    formatTimeOfInjury(timeOfInjuryStr) {
      if (timeOfInjuryStr) {
        let time = this.injury.timeOfInjuryStr;
        // Convert time string which is 24hrs format into 12 hrs with AM/ PM
        const time_part_array = time.split(":");
        let ampm = "AM";
        if (time_part_array[0] >= 12) {
          ampm = "PM";
        }
        if (time_part_array[0] > 12) {
          time_part_array[0] = time_part_array[0] - 12;
        }
        const formatted_time = time_part_array[0] + ":" + time_part_array[1] + " " + ampm;
        return formatted_time;
      }
      return "";
    },
    convertBoolToString(fieldName) {
      if (fieldName === undefined) return;
      let newFieldValue = fieldName.toString();
      // By default value when injury already existed with Bool value true
      if (newFieldValue === "true") return (newFieldValue = "Yes -Observed");
      else if (newFieldValue === "false") return (newFieldValue = "No");
      return newFieldValue;
    },
  },

  async mounted() {
    document.title = `Injury Report - ${this.$route.params.id} `;
    await this.$http
      .get(`/nrl/api/v1/portal/competition-cases/${this.$route.params.id}`)
      .then((res) => {
        this.injury = res.data.data;
        // Player Name
        this.playerName =
          `${this.injury.member.profile.firstName} ${this.injury.member.profile.lastName}` || "";
        // Club Name
        // const activeRole = this.$store.getters["user/activeRole"];

        if (
          this.injury &&
          this.injury.orgtree &&
          this.injury.orgtree.program &&
          this.injury.orgtree.program._id
        )
          this.programName = this.injury.orgtree.program.name;
        else if (this.injury && this.injury.meta && this.injury.meta.gameDayAdmin === true) {
          this.assocName = this.injury.orgtree.association.name;
        } else this.clubName = this.injury.orgtree.club.name || "";
        // Age of Member
        this.age = 0;

        if (
          this.injury &&
          this.injury.member &&
          this.injury.member.profile &&
          typeof this.injury.member.profile.dob === "string"
        ) {
          this.age = calculateAge(this.injury.member.profile.dob, true);
        }

        // First Responder Name and Role
        this.firstResponderName = this.injury.firstResponderName || "";
        this.firstResponderRole = this.injury.firstResponderRole || "";
        // Date and Time
        this.dateOfInj = this.formatDateOfInjury(this.injury.dateOfInjuryStr) || "";
        this.timeOfInj = this.formatTimeOfInjury(this.injury.timeOfInjuryStr) || "";

        if (this.injury && this.injury.concussion) {
          let {
            clinicalFeatures,
            lossOfConsciousness,
            protectiveAction,
            impact,
            balanceDisturbance,
            dazedOrBlank,
            unusualBehaviour,
            confusion,
            memoryImpairment,
            playerReports,
          } = this.injury.concussion;
          this.clinicalFeatures = clinicalFeatures;
          this.lossOfConsciousness = this.convertBoolToString(lossOfConsciousness);
          this.protectiveAction = this.convertBoolToString(protectiveAction);
          this.impact = this.convertBoolToString(impact);
          this.balanceDisturbance = this.convertBoolToString(balanceDisturbance);
          this.dazedOrBlank = this.convertBoolToString(dazedOrBlank);
          this.unusualBehaviour = this.convertBoolToString(unusualBehaviour);
          this.confusion = this.convertBoolToString(confusion);
          this.memoryImpairment = this.convertBoolToString(memoryImpairment);
          this.playerReports = this.convertBoolToString(playerReports);
          // Section D
          this.ambulance = this.clinicalFeatures === true ? true : false;

          if (typeof (this.injury.concussion === Object)) {
            if (
              this.injury.concussion.lossOfConsciousness === "Yes -Observed" ||
              this.injury.concussion.lossOfConsciousness === "Yes -Reported" ||
              this.injury.concussion.protectiveAction === "Yes -Observed" ||
              this.injury.concussion.protectiveAction === "Yes -Reported" ||
              this.injury.concussion.impact === "Yes -Observed" ||
              this.injury.concussion.impact === "Yes -Reported" ||
              this.injury.concussion.balanceDisturbance === "Yes -Observed" ||
              this.injury.concussion.balanceDisturbance === "Yes -Reported" ||
              this.injury.concussion.dazedOrBlank === "Yes -Observed" ||
              this.injury.concussion.dazedOrBlank === "Yes -Reported" ||
              this.injury.concussion.unusualBehaviour === "Yes -Observed" ||
              this.injury.concussion.unusualBehaviour === "Yes -Reported" ||
              this.injury.concussion.confusion === "Yes -Observed" ||
              this.injury.concussion.confusion === "Yes -Reported" ||
              this.injury.concussion.memoryImpairment === "Yes -Observed" ||
              this.injury.concussion.memoryImpairment === "Yes -Reported" ||
              this.injury.concussion.playerReports === "Yes -Observed" ||
              this.injury.concussion.playerReports === "Yes -Reported"
            )
              this.removal = true;
          }
        }
        if (this.injury && this.injury.symptomRecord) {
          const symptomsRec = this.injury.symptomRecord;
          if (symptomsRec) {
            this.questions.forEach((ques) => {
              ques.symptom === "headache" ? Object.assign(ques, symptomsRec.headache) : this.noStep;
              ques.symptom === "pressureInHead"
                ? Object.assign(ques, symptomsRec.pressureInHead)
                : this.noStep;
              ques.symptom === "neckPain" ? Object.assign(ques, symptomsRec.neckPain) : this.noStep;
              ques.symptom === "nauseaOrVomit"
                ? Object.assign(ques, symptomsRec.nauseaOrVomit)
                : this.noStep;
              ques.symptom === "dizzy" ? Object.assign(ques, symptomsRec.dizzy) : this.noStep;
              ques.symptom === "blurVision"
                ? Object.assign(ques, symptomsRec.blurVision)
                : this.noStep;
              ques.symptom === "balanceProblem"
                ? Object.assign(ques, symptomsRec.balanceProblem)
                : this.noStep;
              ques.symptom === "lightSensitivity"
                ? Object.assign(ques, symptomsRec.lightSensitivity)
                : this.noStep;
              ques.symptom === "noiseSensitivity"
                ? Object.assign(ques, symptomsRec.noiseSensitivity)
                : this.noStep;
              ques.symptom === "slowedDown"
                ? Object.assign(ques, symptomsRec.slowedDown)
                : this.noStep;
              ques.symptom === "inAFog" ? Object.assign(ques, symptomsRec.inAFog) : this.noStep;
              ques.symptom === "notFeelRight"
                ? Object.assign(ques, symptomsRec.notFeelRight)
                : this.noStep;
              ques.symptom === "difficultyInConcentrating"
                ? Object.assign(ques, symptomsRec.difficultyInConcentrating)
                : this.noStep;
              ques.symptom === "difficultyRemembering"
                ? Object.assign(ques, symptomsRec.difficultyRemembering)
                : this.noStep;
              ques.symptom === "fatigued" ? Object.assign(ques, symptomsRec.fatigued) : this.noStep;
              ques.symptom === "confused" ? Object.assign(ques, symptomsRec.confused) : this.noStep;
              ques.symptom === "drowsy" ? Object.assign(ques, symptomsRec.drowsy) : this.noStep;
              ques.symptom === "emotional"
                ? Object.assign(ques, symptomsRec.emotional)
                : this.noStep;
              ques.symptom === "irritable"
                ? Object.assign(ques, symptomsRec.irritable)
                : this.noStep;
              ques.symptom === "sad" ? Object.assign(ques, symptomsRec.sad) : this.noStep;
              ques.symptom === "nervousOrAnxious"
                ? Object.assign(ques, symptomsRec.nervousOrAnxious)
                : this.noStep;
            });
            this.playerSelfState = symptomsRec.playerSelfState;
          }
        }
      })
      .catch((error) => {
        this.$store.commit("root/LOADING", false);
        window.scrollTo(0, 0);
        this.$store.commit("views/PUSH_NOTIFICATION", {
          msg:
            error && error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : msg.error.apiError,
          type: "warning",
        });
      });
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&display=swap");
.box {
  border: 2px solid lightgray;
  box-shadow: inset 0.1em 0.1em lightgray;
  padding: 0px 10px;
}
.horizontalSpace {
  padding: 5px 0;
}

#sign {
  font-family: "Dancing Script", cursive;
}
.headings {
  font-weight: bold;
}

input[type="checkbox"]:disabled {
  background-color: red !important;
}
.list {
  list-style-type: disc;
  color: black;
  margin-left: 2.5rem /* 40px */;
}

.tw-text-xl {
  font-size: 1.25rem /* 20px */;
  line-height: 1.75rem /* 28px */;
}

.tw-font-extrabold {
  font-weight: 800;
}

.tw-text-red-600 {
  --tw-text-opacity: 1;
  color: rgba(208, 1, 27, var(--tw-text-opacity));
}

input .checkboxRed {
  --tw-text-opacity: 1;
  border-color: rgba(208, 1, 27, var(--tw-text-opacity));
}

.tw-grid-cols-9 {
  grid-template-columns: repeat(9, minmax(0, 1fr));
}
.tw-text-red-500 {
  --tw-text-opacity: 1;
  color: rgba(239, 68, 68, var(--tw-text-opacity));
}
.tw-text-base {
  font-size: 1rem /* 16px */;
  line-height: 1.5rem /* 24px */;
}

.tw-w-4 {
  width: 1rem /* 16px */;
}

.tw-h-4 {
  height: 1rem /* 16px */;
}

.tw-border-red-600 {
  --tw-border-opacity: 1;
  border-color: rgba(208, 1, 27, var(--tw-border-opacity)) !important;
  /* border-color: red;
  color: red; */
}

.tw-mt-auto {
  margin-top: auto;
}

.tw-mb-1px {
  margin-bottom: 1px;
}

.tw-text-sm {
  font-size: 0.875rem /* 14px */;
  line-height: 1.25rem /* 20px */;
}

.tw-leading-3 {
  line-height: 0.75rem /* 12px */;
}

.tw-grid {
  display: grid;
}
.tw-gap-5 {
  gap: 1.25rem /* 20px */;
}

.tw-grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.tw-gap-5 {
  gap: 1.25rem /* 20px */;
}

.tw-text-justify {
  text-align: justify;
}

.tw-flex-1 {
  flex: 1 1 0%;
}

.tw-w-full {
  width: 100%;
}

.tw-font-medium {
  font-weight: 700 !important;
}

.tw-text-center {
  text-align: center;
}

.tw-text-left {
  text-align: left;
}
.tw-w-1by5 {
  width: 20%;
}

.tw-font-medium {
  font-weight: 500;
}

.tw-justify-self-center {
  justify-self: center;
}

.tw-text-justify {
  text-align: justify;
}

.tw-text-black {
  /* --tw-text-opacity: 1;
  color: rgba(34, 34, 34, var(--tw-text-opacity)); */
  color: black;
}

.tw-text-xs {
  font-size: 0.75rem /* 12px */;
  line-height: 1rem /* 16px */;
}
.tw-col-span-3 {
  grid-column: span 3 / span 3;
}
.tw-font-bold {
  font-weight: 700;
}
.tw-mt-4 {
  margin-top: 1rem /* 16px */;
}
.tw-font-normal {
  font-weight: 400;
}
.tw-w-full {
  width: 100%;
}
.tw-col-span-2 {
  grid-column: span 2 / span 2;
}

.tw-border-r-2 {
  border-right: 2px solid;
  border-color: #e5e7eb;
  /* border-right-width: 2px; */
}

.tw-grid-cols-8 {
  grid-template-columns: repeat(8, minmax(0, 1fr));
}

.tw-border-2 {
  border: 2px solid;
  border-color: #e5e7eb;
  /* border-width: 2px; */
}

.tw-h-6 {
  height: 1.5rem /* 24px */;
}

.tw-w-6 {
  width: 1.5rem /* 24px */;
}
.tw-m-auto {
  margin: auto;
}
.tw-m-0 {
  margin: 0px;
}

.tw-w-1by5 {
  width: 20%;
}

.tw-flex {
  display: flex;
}
.tw-justify-center {
  justify-content: center;
}

.tw-items-center {
  align-items: center;
}

.tw-text-blue-600 {
  --tw-text-opacity: 1;
  color: rgba(37, 99, 235, var(--tw-text-opacity));
}

.tw-text-blue-500 {
  --tw-text-opacity: 1;
  color: rgba(59, 130, 246, var(--tw-text-opacity));
}

.tw-mt-5 {
  margin-top: 1.25rem /* 20px */;
}
.tw-mt-3 {
  margin-top: 0.75rem /* 12px */;
}
.tw-italic {
  font-style: italic;
}
.tw-list-disc {
  list-style-type: disc;
}

.tw-ml-4 {
  margin-left: 1rem /* 16px */;
}
.tw-underline {
  text-decoration: underline;
}

.tw-gap-2 {
  gap: 0.5rem /* 8px */;
}

.tw-col-span-3 {
  grid-column: span 3 / span 3;
}

.tw-text-black {
  --tw-text-opacity: 1;
  color: rgba(34, 34, 34, var(--tw-text-opacity));
}

.tw-w-6 {
  width: 1.5rem /* 24px */;
}

.tw-h-6 {
  height: 1.5rem /* 24px */;
}

.tw-rounded-full {
  border-radius: 9999px;
}

.tw-w-3by5 {
  width: 60%;
}

.tw-mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.tw-bg-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(204, 204, 204, var(--tw-bg-opacity));
}

.tw-bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
}

.tw-h-9 {
  height: 2.25rem /* 36px */;
}

.tw-w-2\/6 {
  width: 33.333333%;
}

.tw-pr-3 {
  padding-right: 0.75rem /* 12px */;
}

.tw-mr-3 {
  margin-right: 0.75rem /* 12px */;
}

.tw-h-24 {
  height: 6rem /* 96px */;
}
.tw-h-full {
  height: 100%;
}

.tw-border {
  border: 1px solid;
  border-color: #e5e7eb;
  /* border-width: 1px; */
}

.tw-border-black {
  --tw-border-opacity: 1;
  border-color: rgba(34, 34, 34, var(--tw-border-opacity));
}

.tw-col-span-1 {
  grid-column: span 1 / span 1;
}
.tw-bg-gray-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
}

.tw-flex-wrap {
  flex-wrap: wrap;
}

.tw-w-1by4 {
  width: 25%;
}

.tw-w-3by4 {
  width: 75%;
}
.tw-w-2by6 {
  width: 33.333333%;
}
.tw-w-1by6 {
  width: 16.666667%;
}

.tw-py {
  padding-top: 0.375rem /* 6px */;
  padding-bottom: 0.375rem /* 6px */;
}

@media print {
  .adjustHeight {
    margin-bottom: 150px;
  }

  /* All your print styles go here */
  .fillBackground {
    background-color: "#C4C4C4" !important;
    color: "#C4C4C4" !important;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
}
</style>

<style>
@media print {
  .header {
    display: none !important;
  }
}
</style>
