<template>
  <div class="rego d-flex-column flex-10">
    <div class="heading" v-if="heading !== false">
      <div class="container">
        <div class="step-heading">{{stepHeading}}<span class="flourish">/</span></div>
      </div>
    </div>
    <div class="flex-10 d-flex-column container p-1 rego-container">
      <slot name="notifications"/>
      <router-view/>
    </div>
  </div>
</template>

<script>
import { mergeMeta } from '@/utils/router';

export default {
  name: 'Default',
  props: ['heading'],
  computed: {
    stepHeading() {
      return mergeMeta(this.$route).heading;
    },
  },
};
</script>

<style lang="scss" scoped>
  .rego-container {
    div {
      width: 100%
    }
  }

.rego {
  height: 100%;
  overflow-x: hidden;
  // background-color: $background;

  .heading {
    z-index: 1;
    background-color: $white;
    box-shadow: 0px 2px 8px 2px rgba(#000000, 0.04);

    @media (max-width: 1263px) {
        height: calc(1.5rem + 28px);
    }
    @media (min-width: 1263px) {
        height: calc(2rem + 36px);
    }

    .step-heading {
      text-transform: uppercase;
      letter-spacing: 0.1rem;

      font-family: $fontHeebo;
      font-weight: 600;

      @media (max-width: 1263px) {
        font-size: 1.5rem;
        margin-top: 8px;
      }
      @media (min-width: 1263px) {
        font-size: 2rem;
        margin-top: 9px;
      }

      .flourish {
        color: $rl-highlight;
        margin-left: 3px;
      }
    }

    .container {
      padding: 2px 15px;
      height: 100%;
    }

    .top {
      color: $secondary;
      text-transform: uppercase;
      letter-spacing: 0.1rem;
      font-size: 0.7rem;
    }
  }
}
</style>
