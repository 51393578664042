<template>
  <div
      class="alert margin-15"
      :class="[alertType, ]"
      v-show="visibleLocal"
  >
    <div class="vertical-center align-text-center align-center">
      <slot></slot>
    </div>
  </div>
</template>

<script>
const classMapping = {
  secondary: 'secondary',
  success: 'secondary',
  black: 'primary',
  warning: 'warning',
  white: 'white',
};

export default {
  name: 'Alert',
  props: {
    type: {
      type: String,
      default: 'success', // primary, secondary
    },
    visible: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      visibleLocal: this.visible,
    };
  },

  methods: {
    close() {
      this.visibleLocal = false;
      this.$emit('close');
    },
  },
  computed: {
    alertType() {
      const map = classMapping[this.type];
      return `alert-${map}`;
    },
  },
};
</script>

<style lang="scss" scoped>

.alert {
  color: white;
  padding: 10px 20px;
  justify-content: center;
  border-radius: 2px;
  margin-bottom: 10px;
}

.alert-primary {
  background-color: $primary;
}

.alert-secondary {
  background-color: $secondary;
}

.alert-success {
  background-color: $secondary;
}

// copied from bootstrap4
.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.alert-white {
  color: $secondary;
  background-color: #ffffff;
  border-color: #ffffff;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
</style>
