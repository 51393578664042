<template>
  <div class="flex-10 d-flex-column container p-1">
    <slot name="notifications"/>
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'DefaultFullWidth',
};
</script>

<style lang="scss" scoped>
.container {
    width: 100%;
    padding: 0;
}

</style>
