/* eslint-disable camelcase */

import Vue from "vue";
import Router from "vue-router";
import flow from "lodash/fp/flow";
import filter from "lodash/fp/filter";
import toPairs from "lodash/fp/toPairs";
import map from "lodash/fp/map";
import difference from "lodash/fp/difference";
import moment from "moment";
import routes from "./routes";
import store from "./store";
import httpnoauth from "@/axios/httpnoauth";
import { redirectRegoView, requiredRegoCurrentFields } from "@/utils/constants/regoFields";
import { mergeMeta } from "@/utils/router";
import { nationalOrgs } from "@/utils/constants";
import { path } from "lodash/fp";

Vue.use(Router);

const router = new Router({
  base: process.env.BASE_URL,
  mode: "history",
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { x: 0, y: 0 };
  },
});

const hasPermission = (to) => {
  // extra check on user is not verified => extra level of permission
  if (mergeMeta(to).requiresVerified) {
    const unverified = store.getters["verify/userRequiresVerification"];
    if (unverified) {
      return false;
    }
  }

  if (mergeMeta(to).requiresAuth) {
    const now = moment().valueOf();
    const expiry = store.getters["user/expiry"];
    const userid = store.getters["user/userid"];
    const isAuthenticated = store.getters["user/isAuthenticated"];
    const refreshToken = store.getters["user/refreshToken"];

    if (isAuthenticated && expiry - 300 > now) {
      // Valid Token
      store.commit("root/LOADING", false);
      return true;
    }
    if (expiry - 300 <= now && refreshToken && userid) {
      // Expired Token
      store.commit("root/LOADING", true);
      // Refresh Token before going to page
      httpnoauth
        .post("/nrl/api/v1/portal/authorize", {
          refresh_token: refreshToken,
          user_id: userid,
        })
        .then((response) => {
          // Refresh Successful - Login User
          store.commit("user/LOGIN", response.data);
          store.commit("root/LOADING", false);
          return true;
        })
        .catch(() => {
          // Failed Refresh - Logout User
          store.commit("user/LOGOUT");
          store.commit("root/LOADING", false);
          return false;
        });
    } else {
      // No Data - Redirect to Login
      store.commit("user/LOGOUT");
      store.commit("root/LOADING", false);
      return false;
    }
  }
  return true;
};

const regoRedirect = (to, from) => {
  if (mergeMeta(to).regoFlow) {
    const currentRego = store.getters["registration/current"];
    const completedRego = store.getters["registration/completed"];

    const currentTeamsRego = store.getters["registrationTeams/current"];

    const pathName = to.name;
    const fromPathName = from.name;

    // get the columns that are not empty from currentRego
    const validFields = flow(
      toPairs,
      filter(([, value]) => value !== undefined),
      map(([key]) => key)
    )(currentRego.type ? currentRego : currentTeamsRego);

    // DEV ONLY
    // const logDev = () => {
    //   if (process.env.NODE_ENV === 'development') {
    //     console.log('Required fields for this page: ', requiredRegoCurrentFields[pathName]);
    //     console.log('Valid fields: ', validFields);
    //     console.log('Missing fields: ', difference(requiredRegoCurrentFields[pathName], validFields));
    //     console.log('Redirecting to: ', redirectRegoView[pathName]);
    //   }
    // };

    switch (pathName) {
      case "regocart":
      case "payment":
      case "checkout":
        if (
          completedRego.length !== 0 ||
          (currentTeamsRego &&
            difference(requiredRegoCurrentFields[pathName], validFields).length === 0)
        ) {
          return null;
        }
        if (completedRego.length === 0 && currentRego.type) {
          return { name: "verificationupload" };
        }
        if (
          currentTeamsRego &&
          difference(requiredRegoCurrentFields[pathName], validFields).length !== 0
        ) {
          return { name: "teamform" };
        }
        return { name: "regotype" };
      default:
        if(fromPathName === "registration" && pathName === "participantdetails") {
          return null;
        }
        if (requiredRegoCurrentFields[pathName]) {
          // default case checks current rego
          if (difference(requiredRegoCurrentFields[pathName], validFields).length !== 0) {
            // logDev();
            return { name: redirectRegoView[pathName] };
          }
        }
        return null;
    }
  } else if (
    store.getters["user/isAuthenticated"] &&
    store.getters["verify/userRequiresVerification"] &&
    to.name !== "logout"
  ) {
    return { name: "verification" };
  }
  return null;
};

router.beforeEach((to, from, next) => {
  // Clean notifications on navigation
  store.commit("views/CLEAR_NOTIFICATION", to.name);

  const permission = hasPermission(to);
  const redirect = regoRedirect(to, from);


  if (process.env.NODE_ENV === "development" && to.query.debug) {
    // Allow debug mode that skip any redirect
    console.log("debugging mode on");
    next();
  } else if (permission) {
    if (redirect) {
      next(redirect);
      return;
    }
    // check if debugging
    const maintenanceCleared = store.getters["user/maintenanceCleared"];
    const maintenanceBegin = store.getters["root/maintenanceBegin"];
    const maintenanceEnded = store.getters["root/maintenanceEnded"];
    const now = Date.now();

    if ((now < maintenanceBegin || now > maintenanceEnded) && to.path == "/maintenance") {
      next("/");
    }
    if (
      now > maintenanceBegin &&
      now < maintenanceEnded &&
      !maintenanceCleared &&
      to.path !== "/maintenance"
    ) {
      next("/maintenance");
    }
    next();
  } else {
    store.dispatch("views/updateLoginRedirect", to ? to.fullPath : undefined);
    next("/login");
  }
});

// Set meta tags
router.beforeEach((to, from, next) => {
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  // Set title
  if (nearestWithTitle) document.title = nearestWithTitle.meta.title;

  // Remove old tags
  Array.from(document.querySelectorAll("data-vue-router-controlled")).map((el) =>
    el.parentNode.removeChild(el)
  );

  if (!nearestWithMeta) return next();

  // Generate and add meta tags to head
  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach((k) => {
        tag.setAttribute(k, tagDef[k]);
      });

      tag.setAttribute("data-vue-router-controlled", "");

      return tag;
    })
    .forEach((tag) => {
      document.head.appendChild(tag);
    });

  return next();
});

// Bue-GTM hooks
// adapted from vue-gtm, but add custom methods
router.afterEach((to) => {
  const { dataLayer } = window;

  // Ignore some routes
  const { gtm = {} } = mergeMeta(to);

  const {
    event = "v_page_view",
    ignore = false,
    reg_flow = (store.getters["registration/current"] || store.getters["registrationTeams/current"])
      .type,
    page_name = to.name,
    nrl_id = store.getters["user/userid"],
  } = gtm;

  const source = store.getters["views/nationalOrg"];
  let org_code = source ? source.code : "mysideline";

  if (ignore) return;

  // Dispatch vue event using meta gtm value if defined otherwise fallback to route name
  const page_path = to.fullPath;

  // Hot Fix: Delay when the redirect from nrl sites hits mysideline and org_code isn't set
  if (!source && to.query && to.query.source && nationalOrgs[to.query.source])
    org_code = to.query.source;

  dataLayer.push({
    event,
    page_path,
    page_name,
    reg_flow,
    nrl_id,
    org_code,
  });
});

// https://github.com/vuejs/vue-router/issues/2833#issuecomment-532158403
const originalPush = router.push;
router.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject);
  }
  return originalPush.call(this, location).catch((err) => {
    if (err) {
      // If there really is an error, throw it
      // Should probably be more sophisticated based on the type of err
      return Promise.reject(err);
    }
    // Otherwise resolve to false to indicate the original push call didn't go to its original
    // destination.
    return Promise.resolve(false);
  });
};

export default router;
