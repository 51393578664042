import Home from "@/views/Home.vue";
import ProfileScreen from "@/views/profile/ProfileScreen.vue";
import registration from "./registration";
import registrationTeams from "./registrationTeams";
import profile from "./profile";
import RewardsScreen from "@/views/rewards/RewardsScreen.vue";
import injuryHistory from "@/views/injury/injuryHistory.vue";

export default [
  {
    path: "/",
    name: "home",
    component: Home,
    meta: {
      layout: "DefaultFullWidth",
      title: "MySideline",
      metaTags: [
        {
          name: "description",
          content: "The official NRL MySideline site.",
        },
        {
          name: "og:description",
          content: "The official NRL MySideline site.",
        },
      ],
    },
  },
  {
    path: "/login",
    name: "login",
    meta: {
      layout: "DefaultNoHeading",
    },
    component: () => import("@/views/auth/Login.vue"),
  },
  {
    path: "/oidc-imc/authorize",
    name: "oidc-imc",
    meta: {
      layout: "DefaultNoHeading",
    },
    component: () => import("@/views/auth/Oidc.vue"),
  },
  {
    path: "/oidc-imc/oauth",
    name: "oidc-imc-oauth",
    meta: {
      layout: "Default",
      heading: "Choose Profile",
    },
    component: () => import("@/views/auth/OidcOauth.vue"),
  },
  {
    path: "/oauth/:type?",
    name: "oauth",
    meta: {
      layout: "DefaultNoHeading",
    },
    component: () => import("@/views/auth/Oauth.vue"),
  },
  {
    path: "/learningcentre/newprofile",
    name: "lcnewprofile",
    component: () => import("@/views/registration/MemberForm.vue"),
    props: { lc: true },
    meta: {
      requiresAuth: true,
      layout: "DefaultNoHeading",
      title: "New Profile – MySideline",
    },
  },
  {
    path: "/learningcentre/linkprofile",
    name: "lclinkprofile",
    component: () => import("@/views/registration/PreviousProfileFound.vue"),
    props: { lc: true },
    meta: {
      requiresAuth: true,
      layout: "DefaultNoHeading",
      title: "Link Profile – MySideline",
    },
  },
  {
    path: "/logout",
    name: "logout",
    meta: {
      layout: "DefaultNoHeading",
    },
    component: () => import("@/views/auth/Logout.vue"),
  },
  {
    path: "/register",
    component: () => import("@/views/registration/Rego.vue"),
    children: [...registration, ...registrationTeams],
    meta: {
      requiresAuth: true,
      regoFlow: true,
      requiresVerified: true, // extra check that user is verified
      heading: "Get Involved",
      metaTags: [
        {
          name: "description",
          content: "Register for rugby league.",
        },
        {
          name: "og:description",
          content: "Register for rugby league.",
        },
      ],
    },
  },
  {
    path: "/injury-history/:id",
    name: "injuryHistory",
    meta: {
      requiresAuth: true,
      regoFlow: true,
      requiresVerified: true, // extra check that user is verified
      heading: "Injury History - HIA Form",
      meta: {
        displayName: "Injury History - HIA Form",
        layout: "DefaultNoHeading",
      },
    },
    component: injuryHistory,
  },
  {
    path: "/rewards",
    name: "rewards",
    component: RewardsScreen,

    meta: {
      heading: "Rewards",
      requiresAuth: true,
      requiresVerified: true,
      metaTags: [
        {
          name: "description",
          content: "Manage rewards for your rugby league account.",
        },
        {
          name: "og:description",
          content: "Manage rewards for your rugby league account.",
        },
      ],
    },
    children: [],
  },
  {
    path: "/profile",
    component: ProfileScreen,
    meta: {
      requiresAuth: true,
      requiresVerified: true,
      metaTags: [
        {
          name: "description",
          content: "Manage linked profiles for your rugby league account.",
        },
        {
          name: "og:description",
          content: "Manage linked profiles for your rugby league account.",
        },
      ],
    },
    children: profile,
  },

  {
    path: "/under-construction",
    name: "under-construction",
    meta: {
      displayName: "Under Construction",
      layout: "DefaultNoHeading",
    },
    component: () => import("@/views/UnderConstruction.vue"),
  },
  {
    path: "/maintenance",
    name: "maintenance",
    meta: {
      displayName: "Maintenance access",
      layout: "DefaultNoHeading",
    },
    component: () => import("@/views/Maintenance.vue"),
  },

  {
    path: "*",
    name: "404",
    meta: {
      layout: "DefaultNoHeading",
    },
    component: () => import("@/views/NotFound.vue"),
  },
];
