const date = new Date();
const currentMonth = date.getMonth();
const currentSeason = currentMonth <= 10 ? date.getFullYear() : date.getFullYear() + 1;

const yesOrNo = [
  { option: "Yes", value: true },
  { option: "No", value: false },
];

const yesNoOptions = [
  { option: "Yes -Observed", value: "Yes -Observed" },
  { option: "Yes -Reported", value: "Yes -Reported" },
  { option: "No", value: "No" },
];

module.exports = { currentSeason, yesOrNo, yesNoOptions };
