// marks the store fields that shall be not-null when going to the certain route.

export const requiredRegoCurrentFields = {

  chooseprofile: [
    'type',
    'entity',
  ],

  memberform: [
    'type',
    'entity',
  ],

  participantdetails: [
    'id',
    'type',
    'entity',
    'profile',
  ],

  additionaldetails: [
    'id',
    'type',
    'entity',
    'profile',
    'profileConfirmed',
    'priceItem',
  ],

  chooseaddon: [
    'id',
    'type',
    'entity',
    'priceItem',
    'profile',
    'profileConfirmed',
    'additionalDetails',
  ],

  verificationupload: [
    'id',
    'type',
    'entity',
    'priceItem',
    'profile',
    'profileConfirmed',
    'additionalDetails',
  ],

  teamform: [
    'type',
    'entity',
  ],

  teamaddon: [
    'type',
    'entity',
    'teamDetails',
    'price',
  ],

  teampayment: [
    'type',
    'entity',
    'teamDetails',
    'price',
  ],

  checkout: [
    'type',
    'entity',
    'teamDetails',
    'price',
  ],
};

// next
export const redirectRegoView = {
  clubsearch: 'regotype',
  chooseprofile: 'clubsearch',
  memberform: 'clubsearch',
  participantdetails: 'chooseprofile',
  additionaldetails: 'participantdetails',
  chooseaddon: 'participantdetails',
  verificationupload: 'chooseaddon',
  teamform: 'compsearch',
  teamaddon: 'teamform',
  teampayment: 'teamform',
};
