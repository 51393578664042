<template>
  <div class="d-flex nav-container">
    <div class="d-flex logo-wrapper center clickable" @click="$router.push({ name: 'regotype' })">
      <img :src="logo" alt="" :class="largeLogo ? 'large' : ''" class="logo" />
    </div>
    <div class="center">
      <ul role="menubar" class="el-menu--horizontal el-menu">
        <li
          class="el-menu-item"
          :key="index"
          :index="String(index)"
          v-for="(item, index) in menu"
          @click="optionSelect(item)"
        >
          <span class="menu-text loose-text s-14"> {{ item.title }} </span>
        </li>
      </ul>
    </div>

    <div class="d-flex mr-1">
      <!-- <i class="white icon-search center mr-2"></i> -->
      <div class="center position-relative">
        <el-button @click="handleProfileButtonClick" class="button-register">
          <span class="white">
            {{ isAuthenticated ? userName : "Register" }}
          </span>
        </el-button>
        <span
          v-if="!isAuthenticated"
          @click="login()"
          class="white upper loose-text login clickable mr-1"
        >
          Log in
        </span>
        <div v-if="showDropDown" class="drop-down d-flex-column">
          <button @click="logout" class="button-invisible option">Log Out</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "HeaderDesktop",
  methods: {
    optionSelect(item) {
      if (item.link && item.path) throw Error("Do not supply both link and path.");
      if (item.link) {
        window.location = item.link;
      }
      if (item.path) {
        this.$router.push({ name: item.path }).catch(() => {});
      }
    },
    login() {
      this.$router.push("/login").catch(() => {});
    },
    logout() {
      this.$router.push("/logout").catch(() => {});
      this.toggleDropDown();
    },
    toggleDropDown() {
      this.showDropDown = !this.showDropDown;
    },
    handleProfileButtonClick() {
      if (this.isAuthenticated) {
        this.toggleDropDown();
      } else {
        this.$router.push({ name: "regotype" }).catch(() => {});
      }
    }
  },
  data() {
    return {
      showDropDown: false
    };
  },
  props: ["menu", "submenu", "nationalOrg"],
  computed: {
    ...mapGetters("views", ["bus"]),
    ...mapGetters("user", ["isAuthenticated", "userName"]),
    logo() {
      if (this.nationalOrg) {
        if (this.nationalOrg.code === "rugby-league") return "/images/PlayRugbyLeague_Logo.svg";
        return "/images/NRL_TouchFootballv2.svg";
      }
      return "/images/MySideline_MobileLogo_white.svg";
    },
    largeLogo() {
      if (this.nationalOrg) return true;
      return false;
    }
  }
};
</script>
<style lang="scss" scoped>
.el-menu-item {
  background-color: rgb(13, 13, 13) !important;
}

.nav-container {
  justify-content: space-between;
  min-height: 100px;
}

.logo-wrapper {
  margin-left: 20px;
  margin-top: 5px;
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    display: block !important;
  }

  .logo {
    height: 2.5rem;
    margin-right: 5px;
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      width: 150px;
    }

    &.large {
      height: 3.5rem;
    }
  }
}

.el-menu {
  background-color: $primary;
  border: none !important;
}

::v-deep .el-submenu__title,
.el-menu-item {
  color: white !important;
  text-align: center;
  border-bottom: none !important;
  padding-right: 20px;
  padding-left: 20px;
}

.position-relative {
  position: relative;
  overflow: visible;
}

.drop-down {
  position: absolute;
  left: 0;
  right: 0;
  top: calc(100% - 3px);
  z-index: 5;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
  border-radius: 0px 0px 4px 4px;
  overflow: hidden;

  .option {
    background-color: $primary;
    color: $white;
    width: 100%;
    font-size: 1rem;
    padding: 10px 5px;
    border-left: 2px solid $secondary !important;
    border-right: 2px solid $secondary !important;
    border-bottom: 2px solid $secondary !important;

    &:hover {
      background-color: $rl-highlight;
      cursor: pointer;
      border-color: $rl-highlight !important;
    }
  }
}

.button-register {
  background: none;
  border-color: $rl-highlight !important;
  min-width: 150px;
  height: calc(0.75rem + 24px);
  padding: 0px !important;
  letter-spacing: 0.15em;
  font-size: 0.75rem;
  color: white;
  text-transform: uppercase;
}

.loose-text {
  letter-spacing: 0.2em;
}

.s-14 {
  font-size: 0.875rem;
}

.menu-text {
  text-transform: uppercase;
  border-bottom: 2px solid $primary;
  &:hover {
    border-bottom: 2px solid $rl-highlight;
    color: $rl-highlight;
  }
}

.active {
  .menu-text {
    border-bottom: 2px solid $rl-highlight;
  }
}

.white {
  color: white;
}

.upper {
  text-transform: uppercase;
}

.center {
  margin-top: auto;
  margin-bottom: auto;
}

.login {
  margin-left: 20px;
  font-size: 0.875rem;
}
</style>
