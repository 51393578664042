import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import root from "./root";
import user from "./user";
import registration from "./registration";
import registrationSession from "./registration/session";
import registrationTeams from "./registration-teams";
import registrationTeamsSession from "./registration-teams/session";
import imcSession from "./imcSession";
import views from "./views";
import viewsSession from "./views/session";
import privateLink from "./privateLink";
// TODO: solve below dependency cycle with router/vuex sync module
import verify from "@/store/verify";

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  key: "nrl-user",
  storage: window.localStorage,
  modules: ["user"],
});

const vuexSessionStorage = new VuexPersistence({
  key: "nrl-user-session",
  storage: window.sessionStorage,
  modules: ["registrationSession", "registrationTeamsSession", "imcSession", "viewsSession"],
});

const modules = {
  root,
  user,
  registration,
  registrationSession,
  registrationTeams,
  registrationTeamsSession,
  views,
  viewsSession,
  verify,
  imcSession,
  privateLink,
};

export default new Vuex.Store({
  plugins: [vuexLocal.plugin, vuexSessionStorage.plugin],
  modules,
});
