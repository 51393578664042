import { nationalOrgs } from "@/utils/constants";

export default {
  namespaced: true,
  state: {
    nationalOrg: undefined,
    loginRedirect: undefined,
  },
  mutations: {
    UPDATE_NATIONAL_ORG(state, update) {
      if (update === "touch-football" || update === "rugby-league") {
        state.nationalOrg = nationalOrgs[update];
      } else {
        state.nationalOrg = undefined;
      }
    },
    UPDATE_LOGIN_REDIRECT(state, update) {
      state.loginRedirect = update;
    },
  },
  getters: {
    nationalOrg(state) {
      return state.nationalOrg;
    },
    loginRedirect(state) {
      return state.loginRedirect;
    },
  },
};
