import randInt from '@/utils/_mock/possibility';

const views = {
  namespaced: true,
  state: {
    askForNewProfileCreation: false,
    eventBus: 0,
    notifications: [], // { id, msg, route, type }
  },
  mutations: {
    ASK_FOR_NEW_PROFILE_CREATION(state, update) {
      state.askForNewProfileCreation = update;
    },
    INCREMENT_EVENT_BUS(state) {
      state.eventBus += 1;
    },
    PUSH_NOTIFICATION(state, {
      msg, route, type, id,
    }) {
      state.notifications = [{
        id, msg, route, type,
      }];
    },
    REMOVE_NOTIFICATION(state, id) {
      state.notifications = state.notifications.filter(
        notification => notification.id !== id,
      );
    },
    CLEAR_NOTIFICATION(state, route) {
      state.notifications = state.notifications.filter(
        notification => notification.route === route,
      );
    },
  },
  actions: {
    triggerEvent({ commit }) {
      commit('INCREMENT_EVENT_BUS');
    },
    notify({ commit }, {
      msg, route, type, autoClose = false, window,
    }) {
      const id = randInt(1, 999999);
      commit('PUSH_NOTIFICATION', {
        msg, route, type, id,
      });
      if (autoClose) {
        setTimeout(() => {
          commit('REMOVE_NOTIFICATION', id);
        }, 5000);
      }
      if (window) window.scrollTo(0, 0);
    },
    updateNationalOrg({ commit }, newOrg) {
      commit('viewsSession/UPDATE_NATIONAL_ORG', newOrg, { root: true });
    },
    updateLoginRedirect({ commit }, update) {
      commit('viewsSession/UPDATE_LOGIN_REDIRECT', update, { root: true });
    },
    clearLoginRedirect({ commit }) {
      commit('viewsSession/UPDATE_LOGIN_REDIRECT', undefined, { root: true });
    },
  },
  getters: {
    bus(state) {
      return state.eventBus;
    },
    nationalOrg(state, getters, rootState) {
      return rootState.viewsSession.nationalOrg;
    },
    loginRedirect(state, getters, rootState) {
      return rootState.viewsSession.loginRedirect;
    },
  },
};

export default views;
