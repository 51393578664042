<template>
  <footer>
    <div class="visible-desktop">
      <FooterDesktop :menu="menu1.concat(menu2)" :optionSelect="optionSelect" />
    </div>
    <div class="visible-mobile">
      <div class="links d-flex mt-2">
        <div class="row d-flex">
          <div
            v-for="(item, index) in menu1"
            :key="index"
            @click="optionSelect(item)"
            class="clickable heebo"
          >
            {{ item.title }}
          </div>
        </div>
        <div class="row d-flex">
          <div
            v-for="(item, index) in menu2"
            :key="index"
            @click="optionSelect(item)"
            class="clickable heebo"
          >
            {{ item.title }}
          </div>
        </div>
      </div>
      <div class="mt-3 mb-1 heebo">
        <div>©{{ currentSeason }} Play Rugby League</div>
      </div>
    </div>
  </footer>
</template>

<script>
import FooterDesktop from "@/components/layout/FooterDesktop.vue";
import { currentSeason } from "@/utils/constants/variables";

export default {
  name: "Footer",
  components: { FooterDesktop },
  methods: {
    optionSelect(item) {
      if (item.link && item.path) throw Error("Do not supply both link and path.");
      if (item.link) window.open(item.link);
      if (item.path) this.$router.push({ name: item.path }).catch(() => {});
    },
  },
  data() {
    return {
      currentSeason,
      menu1: [
        {
          title: "Terms of Use",
          link: "https://www.nrl.com/terms-of-use",
        },
        {
          title: "Privacy Policy",
          link: "https://www.nrl.com/privacy-policy",
        },
      ],
      menu2: [
        {
          title: "Careers",
          link: "https://www.nrl.com/careers",
        },
        {
          title: "Help",
          link: "https://help.nrl.com/",
        },
        {
          title: "Contact Us",
          link: "https://www.nrl.com/contact-us",
        },
        {
          title: "Advertise With Us",
          link: "https://www.nrl.com/advertise-with-us",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
footer {
  background-color: $primary;
  color: rgba($white, 0.7);
  text-align: center;

  .links {
    flex-wrap: wrap;
    justify-content: center;

    .row > div {
      margin-left: 15px;
    }
  }
  .heebo {
    font-size: 12px;
    font-family: "Heebo", sans-serif;
  }
}
</style>
