import Vue from "vue";
import moment from "moment";
import { cloneDeep, isEmpty, isObject } from "lodash";
import fp from "lodash/fp";
import endpoints from "@/utils/constants/api";
import msg from "@/utils/constants/msg";
import randInt from "@/utils/_mock/possibility";
import { totalDiscounts } from "@/utils/registration/pricing";

export const activeKidsVoucherSkeleton = {
  barcode: "",
  pin: "",
  balance: 0,
};

export const clubDiscountVoucherSkeleton = {
  barcode: "",
  balance: 0,
};

export const associationDiscountVoucherSkeleton = {
  barcode: "",
  balance: 0,
};

const regoSkeleton = {
  id: undefined,
  verification: { undefined },
  profile: undefined,
  profileConfirmed: undefined,
  additionalDetails: undefined,
  priceItem: undefined,
  activeKidsVoucher: undefined,
  clubDiscountVoucher: undefined,
  associationDiscountVoucher: undefined,
  documents: undefined,
};

const regoStore = {
  namespaced: true,
  state: {
    completed: [],
    current: regoSkeleton,

    /* ----- START, SEARCH OR ADD MEMBER DATA ------ */
    // Member search and creation
    searchCriteria: {
      firstName: "",
      middleName: "",
      lastName: "",
      dob: "",
      gender: "",
    },
    // Found Members
    memberSearchResult: [],
    /* ----- END, SEARCH OR ADD MEMBER DATA  ------ */

    /* ----- START, PRODUCT PRICE LIST SCHEMA ------ */
    priceList: [
      {
        memberType: undefined, // any one of memberTypes const
        onlinePaymentRequired: undefined, // true or false
        totalPrice: undefined, // Price before discounts/saleable items in cents
        // National, State, Region, Association, Club, Team, Program
        pricing: {
          club: {
            onlinePaymentRequired: undefined, // true or false
            price: undefined, // Cents
            discount: {
              _id: undefined, // String UUID
              amount: undefined, // Cents
              name: undefined, // string, display name
            },
            saleableItems: [
              {
                mandatory: undefined, // true or false
                _id: undefined, // String UUID
                description: undefined,
                name: undefined,
                price: undefined, // Cents
                requireSizes: undefined, // Boolean
              },
            ],
          },
        },
      },
    ],
    /* ----- END, PRODUCT PRICE LIST SCHEMA ------ */
  },
  mutations: {
    UPDATE_DOCUMENTS(state, documents) {
      state.current.documents = documents;
    },
    SEARCH_INPUT(state, { firstName, middleName, lastName, dob, gender }) {
      state.searchCriteria = {
        firstName,
        middleName,
        lastName,
        dob,
        gender,
      };
    },
    SEARCH(state, searchResult) {
      // normalize data and store it directly normalized
      state.memberSearchResult = searchResult.map(({ _id: id, profile }) => ({ id, ...profile }));
    },
    UPDATE_CURRENT(state, update) {
      // complete a step / change a part of a form
      const localUpdate = cloneDeep(update);
      // clean undefined fields - so no overwriting existing data with empty
      Object.keys(localUpdate).forEach((key) => {
        if ([undefined].includes(localUpdate[key])) delete localUpdate[key];
        if (typeof localUpdate[key] === "object" && isEmpty(localUpdate[key])) {
          delete localUpdate[key];
        }
      });
      // state.current = merge(state.current, localUpdate);
      state.current = {
        ...state.current,
        ...localUpdate,
      };
    },
    MODIFY_COMPLETED(state, { index, update }) {
      Vue.set(state.completed, index, { ...state.completed[index], ...update });
    },
    DELETE_COMPLETED(state, index) {
      state.completed.splice(index, 1);
    },
    CLEAR_COMPLETED(state) {
      state.completed = [];
    },
    // push a completed form to RegoCart
    COMPLETED(state, rego) {
      state.completed.push(rego);
    },
    CLEAR_CURRENT(state) {
      state.current = regoSkeleton;
    },
    SET_PRICELIST(state, update) {
      state.priceList = update;
    },
  },
  getters: {
    verificationPending: (state) =>
      state.current.verification && state.current.verification.pending,
    hasDocuments: (state) => state.current.documents && state.current.documents.length > 0,
    getCurrentDocuments: (state) => state.current.documents,
    getCurrentMemberId: (state) => state.current.id,
    getCurrentMemberEmail: (state) => state.current.profile.participantForm.email,
    getRegoIndex: (state) => (regoOrId) => {
      const localId = regoOrId.localId ? regoOrId.localId : regoOrId;
      return state.completed.findIndex((rego) => rego.localId === localId);
    },
    // below gets rego by rego or id (depending on which is passed)
    getRego: (state, getters) => (regoOrId) => getters.getAllRegos[getters.getRegoIndex(regoOrId)],
    getAllRegos: (state) => state.completed,
    current(state, getters, rootState) {
      // since we are grabbing rego from different places
      const persistent = rootState.registrationSession;
      return {
        ...state.current,
        ...persistent.current,
      };
    },
    completed(state) {
      return state.completed;
    },
    // send below to api
    finishedRegoList(state) {
      return state.completed.map((registration) => {
        const totalDiscount = totalDiscounts(registration, state.completed);
        const pricing = fp.flow(
          fp.toPairs, // = Object.entries
          fp.map(([key, priceObj]) => [
            key,
            Object.assign({}, priceObj, {
              saleableItems: fp.filter((item) => item.qty > 0)(priceObj.saleableItems),
              totalDiscount,
            }),
          ]), // transformation
          fp.fromPairs // reverse of Object.entries
        )(registration.priceItem.pricing);

        const meta = isObject(registration.additionalDetails)
          ? { ...registration.additionalDetails }
          : {};

        let answers;
        if (meta.answers) {
          // eslint-disable-next-line prefer-destructuring
          answers = meta.answers;
          delete meta.answers;
        }

        return {
          memberId: registration.id,
          entityType: registration.entity.entityType,
          entityId: registration.entity._id,
          memberType: registration.priceItem.memberType,
          activeKidsVoucher: registration.activeKidsVoucher,
          clubDiscountVoucher: registration.clubDiscountVoucher,
          associationDiscountVoucher: registration.associationDiscountVoucher,
          programSettingsDiscountVoucher: registration.programSettingsDiscountVoucher,
          programDiscountVoucher: registration.programDiscountVoucher,
          pricing, // need to remove empty saleable items
          meta,
          answers,
        };
      });
    },
    // below related to pricing
    getAvailableProducts: (state) => (state.priceList ? state.priceList : []),
    getAvailableProductsRealConcat(state) {
      const {
        current: { priceItem },
      } = state;
      const result = [];
      if (priceItem) {
        // Get the current Date
        const currentDate = new Date();
        const timestampDate = currentDate.getTime();
        Object.entries(priceItem.pricing).forEach(([category, value]) => {
          if (value && value.saleableItems) {
            const { saleableItems } = value;
            saleableItems.forEach((item) => {
              const { qty } = item;
              if (item.seasonPassFrom && item.seasonPassTo && item.seasonPass) {
                if (timestampDate >= item.seasonPassFrom && timestampDate <= item.seasonPassTo) {
                  result.push({
                    category, // record which category the item is for ease of updating
                    ...item,
                    qty: qty || 0,
                  });
                }
              } else {
                result.push({
                  category, // record which category the item is for ease of updating
                  ...item,
                  qty: qty || 0,
                });
              }
            });
          }
        });
      }
      return result;
    },
  },
  actions: {
    async updateCurrent(
      { commit },
      {
        id,
        profile,
        profileConfirmed,
        additionalDetails,
        type,
        entity,
        priceItem,
        totalPrice,
        documents,
        seasonPasses,
      }
    ) {
      // commit in the current store
      const { id: idWithinProfile, verification, ...rest } = profile || {};

      const idToUpdate = id || idWithinProfile; // probably grabbing id from either
      commit("UPDATE_CURRENT", {
        id: idToUpdate,
        verification,
        profile: rest,
        profileConfirmed,
        additionalDetails,
        priceItem,
        totalPrice,
        documents,
        seasonPasses,
      });

      // commit in the persistent store
      commit(
        "registrationSession/UPDATE_CURRENT",
        {
          type,
          entity,
        },
        { root: true }
      );
    },
    /**
     * Create member record
     * @param {*} vim
     * @param {*} criteria
     * @return {Boolean} SUCCESS === Member record created
     */
    // eslint-disable-next-line no-unused-vars
    async create(
      { commit, dispatch },
      { vm, criteria: { firstName, middleName, lastName, dob, gender } }
    ) {
      commit("root/LOADING", true, { root: true });
      // Normalize date pre-send
      const dobNormal = moment(dob).format("YYYY-MM-DD");
      let SUCCESS = false;

      try {
        const response = await vm.$http.post(endpoints.CREATE_MEMBER, {
          firstName,
          middleName,
          lastName,
          dob: dobNormal,
          gender,
        });
        SUCCESS = response.data;
        commit("root/LOADING", false, { root: true });
      } catch (e) {
        commit(
          "views/PUSH_NOTIFICATION",
          {
            msg: msg.error.memberProfileCreate,
            type: "warning",
          },
          { root: true }
        );
        commit("root/LOADING", false, { root: true });
        SUCCESS = false;
      }

      return SUCCESS;
    },
    clearCurrent({ commit }) {
      // clear the persistent store
      commit("registrationSession/CLEAR_CURRENT", {}, { root: true });
      // clear local
      commit("CLEAR_CURRENT");
    },
    clearOnlyCurrentRego({ commit }) {
      // clear local
      commit("CLEAR_CURRENT");
    },
    /**
     * Search for member records
     * @param {*} vm
     * @param {*} criteria
     */
    async search({ commit }, { vm, criteria: { firstName, middleName, lastName, dob } }) {
      commit("root/LOADING", true, { root: true });
      // Normalize date pre-send
      const dobNormal = moment(dob).format("YYYY-MM-DD");
      try {
        const response = await vm.$http.post(endpoints.SEARCH_MEMBER, {
          firstName,
          middleName,
          lastName,
          dob: dobNormal,
        });
        // Store search results
        commit("SEARCH", response.data.data);
      } catch (e) {
        commit(
          "views/PUSH_NOTIFICATION",
          {
            msg: msg.error.apiError,
            type: "warning",
          },
          { root: true }
        );
        commit("root/LOADING", false, { root: true });
      }
    },
    async submit({ commit, getters }) {
      // used for submit the currentRego into completed
      const localId = randInt(1, 999999); // used for frontend tracking which rego needs update

      commit("COMPLETED", { ...getters.current, localId });

      commit(
        "views/PUSH_NOTIFICATION",
        {
          msg: msg.success.regoAdd,
          route: "regocart",
          type: "white",
        },
        { root: true }
      );

      commit("CLEAR_CURRENT");
      commit("registrationSession/CLEAR_CURRENT", null, { root: true });
    },
    updateCompleted({ commit, getters }, { rego, update }) {
      const index = getters.getRegoIndex(rego);
      commit("MODIFY_COMPLETED", {
        index,
        update,
      });
    },
    async edit({ commit, getters, dispatch }, payload) {
      // The reverse of `submit`
      await dispatch("updateCurrent", getters.getRego(payload));
      // Remove the rego from completed
      commit("DELETE_COMPLETED", getters.getRegoIndex(payload));
    },
  },
};

export default regoStore;
