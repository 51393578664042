/* eslint-disable import/prefer-default-export */

/**
 * description: get merged meta for the route, from itself and any levels of parents,
 * with child's meta taking priority.
 * @param:
 *   - route
 * @return:
 *   - meta
 */
export const mergeMeta = (route) => {
  let merged = {};
  route.matched.forEach(
    (matchedRoute) => {
      merged = Object.assign(merged, matchedRoute.meta);
    },
  );
  return merged;
};
