<template>
  <div class="d-flex nav-container">
    <div style="width: 20px" />
    <!-- use of inline style is justified in this case -->
    <div class="center">
      <el-menu default-active="1" mode="horizontal" background-color="#0d0d0d">
        <el-menu-item
          v-for="(item, index) in menu"
          :key="index"
          :index="String(index)"
          @click="optionSelect(item)"
        >
          <span class="submenu-text">{{ item.title }}</span>
        </el-menu-item>
      </el-menu>
    </div>
    <div class="d-flex mr-1">
      <div class="center submenu-text">
        <p>
          ©<span>{{ currentSeason }}</span> National Rugby League
        </p>
      </div>
    </div>
    <div style="width: 20px"></div>
    <!-- use of inline style is justified in this case -->
  </div>
</template>

<script>
import { currentSeason } from "@/utils/constants/variables";

export default {
  name: "FooterDesktop",
  props: {
    menu: Array,
    optionSelect: Function,
  },
  data() {
    return {
      currentSeason,
    };
  },
};
</script>
<style lang="scss" scoped>
.nav-container {
  justify-content: space-between;
  height: 75px;
}

.logo-wrapper {
  margin-left: 15px;
  margin-top: 5px;

  .logo {
    height: 55px;
    margin-right: 5px;
  }

  .logo-text-wrapper {
    padding-top: 10px;
    line-height: 15px;

    .logo-text {
      white-space: nowrap;
      display: inline-block;
      color: white;
      text-transform: uppercase;
      font-size: 15px;
    }
  }
}

.el-menu {
  background-color: $primary;
  border: none !important;
}

::v-deep .el-submenu__title,
.el-menu-item {
  color: white !important;
  border-bottom: none !important;
  padding-right: 15px;
  padding-left: 15px;
}

.loose-text {
  letter-spacing: 0.2em;
}

.s-8 {
  font-size: 0.8em;
}

.menu-text {
  text-transform: uppercase;
}

.submenu-text {
  font-size: 12px;
  color: #cfcfcf;
}

.is-active {
  .menu-text {
    border-bottom: 2px solid $secondary;
  }
}

.center {
  margin-top: auto;
  margin-bottom: auto;
}
</style>
