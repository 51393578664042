<template>
  <div
    style="width:330px;display:flex;flex-direction:column;text-align:center;align-items:center;background-color:white;box-shadow:0px 2px 6px rgba(0, 0, 0, 0.06);padding:20px 16px 20px 16px;"
  >
    <div style="font-family: 'RL2-Medium';font-size:16px;color: #222222;">
      How to use your code
    </div>
    <div
      style="font-family: 'RL2-Medium';line-height:26px;padding:20px 0 0 0;font-size:16px;color: #222222;"
    >
      Step 1
    </div>
    <div
      style="line-height:26px;padding:0px 0 0px 0;font-size:14px;font-weight:400;color: #222222;"
    >
      Copy your code above
    </div>
    <div
      style="font-family: 'RL2-Medium';line-height:26px;padding:20px 0 0 0;font-size:16px;color: #222222;"
    >
      Step 2
    </div>
    <div
      style="line-height:26px;padding:0px 0 0px 0;font-size:14px;font-weight:400;color: #222222;"
    >
      Go to
      <a style="color:#098255;" href="https://www.playrugbyleague.com/rewards"
        >https://www.playrugbyleague.com/<br />rewards</a
      >
      to find available matches
    </div>
    <div
      style="font-family: 'RL2-Medium';line-height:26px;padding:20px 0 0 0;font-size:16px;color: #222222;"
    >
      Step 3
    </div>
    <div
      style="line-height:26px;padding:0px 0 0px 0;font-size:14px;font-weight:400;color: #222222;"
    >
      Enter your code in the relevant section of ticketing page
    </div>
    <div
      style="font-family: 'RL2-Medium';line-height:26px;padding:20px 0 0 0;font-size:16px;color: #222222;"
    >
      Step 4
    </div>
    <div
      style="line-height:26px;padding:0px 0 0px 0;font-size:14px;font-weight:400;color: #222222;"
    >
      Enjoy the game!
    </div>
    <div
      @click="$emit('closeSteps')"
      style="cursor:pointer;line-height:26px;padding:10px 0 0px 0;font-size:14px;font-weight:400;color: #098255;text-decoration:underline;"
    >
      Close
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
