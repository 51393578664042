import Vue from 'vue';
import Element from 'element-ui';
import '../styles/element-variables.scss';
// mixins
import '../styles/layout.scss';
import '../styles/color.scss';
import '../styles/elements/form.scss';
import '../styles/elements/card.scss';
import '../styles/elements/button.scss';
import '../styles/elements/checkbox.scss';
import '../styles/miscellaneous.scss';
import '../styles/_icons.scss';
import '../styles/browser-specific.scss';
import locale from 'element-ui/lib/locale/lang/en';

Vue.use(Element, { locale });
