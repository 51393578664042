import jwtDecode from "jwt-decode";

const user = {
  namespaced: true,
  state: {
    maintenanceCleared: false,

    authenticated: false,
    tokens: {},
    profile: {}
  },
  mutations: {
    DEBUGGING(state, update) {
      state.maintenanceCleared = update;
    },
    LOGIN(state, tokens) {
      const updateuser = jwtDecode(tokens.id_token);
      state.profile = updateuser;
      state.tokens = tokens;
      state.authenticated = true;

      // Set First Role as Active
    },
    LOGOUT(state) {
      state.authenticated = false;
      state.tokens = {};
      state.profile = {};
    },
    UPDATE(state, update) {
      // primarily used for updating user status of `verified`
      state.profile = {
        ...state.profile,
        ...update
      };
    }
  },
  actions: {
    // need to manage GTM here
    login({ commit, getters }, payload) {
      commit("LOGIN", payload);
      window.dataLayer.push({
        event: "logged_in",
        nrl_id: getters.userid
      });
    },
    update({ commit }, update) {
      commit("UPDATE", update);
    }
  },
  getters: {
    maintenanceCleared(state) {
      return state.maintenanceCleared;
    },

    isAuthenticated(state) {
      return state.authenticated;
    },
    userid(state) {
      return state.profile._id;
    },
    expiry(state) {
      return state.tokens.expires_in;
    },
    accessToken(state) {
      return state.tokens.access_token;
    },
    refreshToken(state) {
      return state.tokens.refresh_token;
    },
    userName(state) {
      return `${state.profile.firstName}`;
    },
    uploadHeaders(state) {
      const { profile, tokens } = state;
      const authheader = `${profile._id}:${tokens.access_token}`;
      const encodedString = btoa(authheader);
      const Authorization = `Bearer ${encodedString}`;
      return { Authorization };
    },
    userProfile(state) {
      return state.profile;
    }
  }
};

export default user;
