<template>
  <div class="header">
    <nav class="desktop" :class="[!isAuthenticated ? 'visible-desktop' : 'hidden']">
      <HeaderDesktop :menu="menu" :nationalOrg="nationalOrg" />
    </nav>
    <nav class="mobile" :class="[!isAuthenticated ? 'visible-mobile' : null]">
      <HeaderMobile :menu="menu" :nationalOrg="nationalOrg" />
    </nav>
  </div>
</template>

<script>
import _ from "lodash";
import { mapGetters, mapMutations } from "vuex";
import HeaderDesktop from "@/components/layout/HeaderDesktop.vue";
import HeaderMobile from "@/components/layout/HeaderMobile.vue";

export default {
  name: "Header",
  components: {
    HeaderMobile,
    HeaderDesktop,
  },
  methods: {
    ...mapMutations("views", ["INCREMENT_EVENT_BUS"]),
  },
  computed: {
    ...mapGetters("user", ["isAuthenticated"]),
    nationalOrg() {
      return this.$store.getters["views/nationalOrg"];
    },
    menu() {
      // based on is-authenticated, have different layouts
      let menu;
      if (!this.isAuthenticated) {
        menu = [
          {
            title: "Play",
            link:
              this.nationalOrg && this.nationalOrg.code === "touch-football"
                ? "https://touchfootball.com.au/play/"
                : "https://www.playrugbyleague.com/play/",
          },
          {
            title:
              this.nationalOrg && this.nationalOrg.code === "touch-football"
                ? "Volunteer"
                : "Assist",
            link:
              this.nationalOrg && this.nationalOrg.code === "touch-football"
                ? "https://touchfootball.com.au/volunteer/"
                : "https://www.playrugbyleague.com/assist/",
          },
          {
            title: "Learn",
            link:
              this.nationalOrg && this.nationalOrg.code === "touch-football"
                ? "https://touchfootball.com.au/learn/"
                : "https://www.playrugbyleague.com/learn/",
          },
          {
            title: "Competitions",
            link:
              this.nationalOrg && this.nationalOrg.code === "touch-football"
                ? "https://www.touchfootball.com.au/competitions"
                : "https://www.playrugbyleague.com/competitions/",
          },
          {
            title:
              this.nationalOrg && this.nationalOrg.code === "touch-football" ? "News" : "Schools",
            link:
              this.nationalOrg && this.nationalOrg.code === "touch-football"
                ? "https://touchfootball.com.au/news/"
                : "https://www.playrugbyleague.com/schools/",
          },
          {
            title: "Safety",
            link: "https://www.playrugbyleague.com/play/safety/",
          },
          {
            title: "Shop",
            link:
              this.nationalOrg && this.nationalOrg.code === "touch-football"
                ? "https://touchfootball.shopdesq.com/"
                : "https://shop.playnrl.com/",
          },
        ];

        if (this.nationalOrg && this.nationalOrg.code === "touch-football")
          _.remove(menu, (m) => m.title === "Safety");
      } else {
        // const learning = process.env.VUE_APP_PIN_ENV === 'test'
        //   ? 'https://learnstage.playnrl.com/ilp/pages/openidconnect-request.jsf'
        //   : 'https://learn.playrugbyleague.com/ilp/pages/openidconnect-request.jsf';
        menu = [
          {
            title: "My Profile",
            path: "profile",
          },
          {
            title: "Rewards",
            path: "rewards",
          },
          // {
          //   title: 'Learning Centre',
          //   link: learning,
          // },
          {
            title: "Registration History",
            path: "registrationhistory",
          },
          {
            title: "Clearance History",
            path: "clearancehistory",
          },
          {
            title: "Transactions",
            path: "transactions",
          },
          {
            title: "Player Stats",
            path: "playerstats",
          },
          {
            title: "Accreditations",
            path: "accreditations",
          },
          {
            title: "Dispensations",
            path: "dispensations",
          },
          {
            title: "Settings",
            path: "accountsettings",
          },
        ];
      }
      return menu;
    },
  },
  watch: {},
  data() {
    return {
      reactiveKey: 0,
    };
  },
};
</script>

<style lang="scss" scoped>
.hidden {
  display: none;
}

.mobile {
  height: calc(1.5rem + 20px);
}

header {
  text-align: center;
}
h1 {
  font-size: 1.3rem;
  text-transform: uppercase;
  color: $white;
}
</style>

<style>
.header {
  width: 100%;
  background-color: #0d0d0d;
  flex: 0 0 auto;
}
</style>
