export default [
  {
    path: "",
    name: "profile",
    component: () => import("@/views/registration/ChooseProfile.vue"),
    props: { profileScreen: true },
    meta: {
      layout: "DefaultNoHeading",
      title: "Manage Profiles – MySideline",
    },
  },
  {
    path: "edit/:id",
    name: "editprofile",
    component: () => import("@/views/registration/RegistrationForm.vue"),
    props: (route) => ({ profileScreen: true, id: route.params.id }),
    meta: {
      layout: "DefaultNoHeading",
      title: "Edit Profile – MySideline",
    },
  },
  {
    path: "newprofile",
    name: "newprofile",
    component: () => import("@/views/registration/MemberForm.vue"),
    props: { profileScreen: true },
    meta: {
      layout: "DefaultNoHeading",
      title: "New Profile – MySideline",
    },
  },
  {
    path: "linkprofile",
    name: "linkprofile",
    component: () => import("@/views/registration/PreviousProfileFound.vue"),
    props: { profileScreen: true },
    meta: {
      layout: "DefaultNoHeading",
      title: "Link Profile – MySideline",
    },
  },
  {
    path: "registrationhistory",
    name: "registrationhistory",
    component: () => import("@/views/profile/RegistrationHistory.vue"),
    meta: {
      heading: "Registration History",
      title: "Registration History – MySideline",
      metaTags: [
        {
          name: "description",
          content: "Rugby League participant registration records.",
        },
        {
          name: "og:description",
          content: "Rugby League participant registration records.",
        },
      ],
    },
  },
  {
    path: "clearancehistory",
    name: "clearancehistory",
    component: () => import("@/views/profile/Clearances.vue"),
    meta: {
      heading: "Clearance History",
      title: "Clearance History – MySideline",
      metaTags: [
        {
          name: "description",
          content: "Rugby League participant clearance records.",
        },
        {
          name: "og:description",
          content: "Rugby League participant clearance records.",
        },
      ],
    },
  },
  {
    path: "playerstats",
    name: "playerstats",
    component: () => import("@/views/profile/PlayerStats.vue"),
    meta: {
      heading: "Player Stats",
      title: "Player Stats – MySideline",
      metaTags: [
        {
          name: "description",
          content: "Rugby League player stats.",
        },
        {
          name: "og:description",
          content: "Rugby League player stats.",
        },
      ],
    },
  },
  {
    path: "accreditations",
    name: "accreditations",
    component: () => import("@/views/profile/Accreditations.vue"),
    meta: {
      heading: "Accreditations",
      title: "Accreditations – MySideline",
      metaTags: [
        {
          name: "description",
          content: "Rugby League participant Accreditations records.",
        },
        {
          name: "og:description",
          content: "Rugby League participant Accreditations records.",
        },
      ],
    },
  },
  {
    path: "dispensations",
    name: "dispensations",
    component: () => import("@/views/profile/Dispensation.vue"),
    meta: {
      heading: "Dispensations",
      title: "Dispensations – MySideline",
      metaTags: [
        {
          name: "description",
          content: "Rugby League participant Dispensations records.",
        },
        {
          name: "og:description",
          content: "Rugby League participant Dispensations records.",
        },
      ],
    },
  },
  {
    path: "dispensations/add",
    name: "adddispensations",
    component: () => import("@/views/profile/DispensationAdd.vue"),
    meta: {
      heading: "Add Dispensations",
      title: "Add Dispensations – MySideline",
      metaTags: [
        {
          name: "description",
          content: "Rugby League participant Add Dispensations records.",
        },
        {
          name: "og:description",
          content: "Rugby League participant Add Dispensations records.",
        },
      ],
    },
  },
  {
    path: "transactions",
    name: "transactions",
    component: () => import("@/views/profile/Transactions.vue"),
    meta: {
      heading: "Transactions",
      title: "Transactions – MySideline",
      metaTags: [
        {
          name: "description",
          content: "Rugby League participant transaction records.",
        },
        {
          name: "og:description",
          content: "Rugby League participant transaction records.",
        },
      ],
    },
  },
  {
    path: "accountsettings",
    name: "accountsettings",
    component: () => import("@/views/profile/AccountSettings.vue"),
    meta: {
      heading: "Account Settings",
      title: "Profile Settings – MySideline",
      metaTags: [
        {
          name: "description",
          content: "Manage registered rugby league profiles.",
        },
        {
          name: "og:description",
          content: "Manage registered rugby league profiles.",
        },
      ],
    },
  },
];
