<template>
  <div>
    <div
      v-if="showingSteps && overl"
      style="z-index:200;position:fixed;top:5%;left:50%;margin-left:-160px; "
    >
      <steps @closeSteps="closeStep" style="position:absolute;top:100px;"></steps>
    </div>
    <select
      style="box-shadow:0px 2px 6px rgba(0, 0, 0, 0.06);textTransform:uppercase;width:100%;padding:6px 10px 6px 0;border-style:none;margin-bottom:10px;"
      v-model="myProfile"
    >
      <option v-bind:value="profile" v-for="profile of myProfileList" :key="profile._id"
        >{{ profile.firstName }} {{ profile.lastName }}</option
      >
    </select>
    <mycode
      v-if="myProfile && myProfile.reward"
      :profile="myProfile"
      @openSteps="showStep"
    ></mycode>

    <nocode v-if="myProfile && !myProfile.reward"></nocode>
    <div style="height:19px"></div>
    <div style="width:100%;padding:10px 10px 10px 10px;display:flex;">
      <a
        href="https://www.playrugbyleague.com/rewards"
        style="flex:1;text-align:center;cursor:pointer;padding:10px 10px 10px 10px;border-radius:4px; font-size:14px;font-weight:500;background-color: #098255;color:white;text-decoration:none;letter-spacing:4px;"
      >
        SEE AVAILABLE MATCHES
      </a>
    </div>
    <!-- <games></games> -->
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import Steps from "./Steps.vue";
import Mycode from "./Mycode.vue";
import Nocode from "./Nocode.vue";
import Games from "./Games.vue";
import msg from "@/utils/constants/msg";

export default {
  components: { Games, Mycode, Nocode, Steps },
  name: "Maintenance",
  mounted() {
    this.LOADING(true);
    this.$http
      .get("/nrl/api/v1/portal/members")
      .then(membersResponse => {
        if (
          membersResponse.data &&
          membersResponse.data.status &&
          membersResponse.data.status === "success"
        ) {
          if (membersResponse.data.data) {
            const members = membersResponse.data.data.map(
              ({
                _id: id,
                profile,
                reward,
                verification,
                documents,
                activeRegos,
                seasonPasses
              }) => ({
                id,
                verification,
                ...profile,
                activeRegos,
                seasonPasses,
                documents,
                reward,
                meta: { ...profile.meta }
              })
            );
            this.myProfileList = members.filter(profile => {
              return profile.verification && profile.verification.pending === false;
            });
            this.myProfile = this.myProfileList[0];
          }
        } else {
          this.PUSH_NOTIFICATION({
            msg: msg.error.apiError,
            route: this.$route.name,
            type: "warning"
          });
        }
        this.LOADING(false);
      })
      .catch(() => {
        this.PUSH_NOTIFICATION({
          msg: msg.error.apiError,
          route: this.$route.name,
          type: "warning"
        });
        this.LOADING(false);
      });
  },
  computed: {
    overl() {
      return this.$store.getters["root/rootOverlay"];
    }
  },
  data() {
    return {
      showingSteps: false,
      code: "",
      myProfileList: [],
      myProfile: {
        id: ""
      }
    };
  },
  methods: {
    ...mapActions("views", ["notify"]),
    ...mapMutations("views", ["ASK_FOR_NEW_PROFILE_CREATION", "PUSH_NOTIFICATION"]),
    ...mapMutations("root", ["LOADING", "OVERLAY"]),
    closeStep() {
      this.OVERLAY(false);
      this.showingSteps = false;
    },
    showStep() {
      this.OVERLAY(true);
      this.showingSteps = true;
    }
  }
};
</script>

<style scoped lang="scss">
.main {
  max-width: 500px;
}
.card {
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  text-align: center;

  img {
    width: 70%;
  }
}
</style>
